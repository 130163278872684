:root {
    --primary: #C2A63F !important;
    --tertiary: #485473 !important;
    --accent: #FFFFFF !important;
    --accenttwo: #DADADA !important;
    --darkblack: #000 !important;
    --black: #2E2E2E !important;
    --blacktwo: #252525 !important;
    --blackthree: #A8A8A8 !important;
    --secblack: #2D2D2D !important;
    --secblackone: #717171 !important; //cross icon color, back icon too
    --secblacktwo: rgba(85, 81, 81, 0.5) !important;
    --grey: #445261 !important;
    --greyone: #64707C !important;
    --greytwo: rgba(128, 145, 163, 0.5) !important;
    --greythree: rgba(128, 145, 163, 0.3) !important;
    --greyfour: #64707C;
    --secondarygrey: #404040;
    --background: #101010 !important;
    --secondarybackground: #FAFAFA !important;
    --green: #27AE60 !important;
    --greenone: #219653 !important;
    --greenBackground: #219653 !important;
    --redBackground: #FF4C4C !important;
    --red: #F74747 !important;
    --option: #F8F9F9 !important;
    --optionhover: #F6F6F6 !important;
    --white: #fff !important;
    --disabledbutton: #C8D3DC !important;
    --stroke: #ECEEEF !important;
    --border-bottom: #4990EF !important;
    --dark-blue: #C2A63F !important;
    --green: var(--primary) !important;
    --greyborder: #E3E5E7;
    --greytext: #DBE0EB;
    --hover: #0b6aea !important;
    --graybackground: #E5E5E5 !important;
    --errorborder: #FF4C4C !important;
    --errorbackground: #FFF2F2 !important;
    --info: #445261 !important;
    --statusbackground: #E4F2FF !important;
    --blueone: #2484e8 !important;
    --bluetwo: #56CCF2 !important;
    --graybg: #1f1f1f !important;
}

@media print{
  :root{
    --background: #fff !important;
    --accent:#000!important;
    --accenttwo:#2D2D2D!important;
    --blacktwo: #fff !important;
  }
  p{
    color:#000!important;
  }
  a{
    color:#000!important;
  }
  hr{
    display: none;
  }
}

/**
  sp-theme we got from sbic
*/

// @import "./grid.scss";
// @import "./fonts.scss";
// @import "./normalise.scss";
/**
     * Set up a decent box model on the root element
     */
html {
  box-sizing: border-box;
}

/**
         * Make all elements from the DOM inherit from the parent box-sizing
         * Since `*` has a specificity of 0, it does not override the `html` value
         * making all elements inheriting from the root box-sizing value
         * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
         */
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  background-color: #1a1a1a;
  background-image: linear-gradient(89deg, #0f0f0f 25%, #1a1a1a 51%);
  font-family: 'Open Sans', sans-serif;
}
p {
  font-family: 'Open Sans', sans-serif;
}

/**
         * Basic styles for links
         */
a,
a:hover,
.sp-link {
  color: #fff;
  text-decoration: none;
  font-size: inherit;
  font-family: 'Open Sans', sans-serif;
}
.sp-link.underlined {
  text-decoration: underline;
}
.sp-icon-link {
  display: inline-flex;
  align-items: center;
}
.sp-icon-link span {
  position: relative;
}
.sp-icon-link.white span:before {
  background-color: #fff;
}
.sp-icon-link span::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  transition: 0.5s;
  background-color: #c2a63f;
}
.sp-icon-link img {
  width: 18px;
  margin-right: 8px;
}
.sp-link.small {
  font-size: 14px;
}
.sp-link.gold {
  color: #c2a63f;
}
.sp-link.gray.active {
  color: #fff;
}
.sp-underlined-link,
.sp-underlined-link:hover {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  color: #8a8a96;
}
.sp-underlined-link.active {
  color: #fff;
}
.sp-underlined-link:before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0%;
  height: 1px;
  transition: 0.5s;
  background-color: #c2a63f;
}

.sp-underlined-link:hover::before,
.sp-underlined-link.active:before {
  width: 100%;
}
.sp-link.arrow-link {
  background-image: url("../../src/assets/sbic-images/icons/right-arrow.svg");
  padding-right: 42px;
  background-repeat: no-repeat;
  background-position: calc(100% - 4px) 0px;
  transition: 0.3s;
  background-size: contain;
  line-height: 20px;
}
.sp-link.block-center {
  display: inlin;
}
.sp-link.arrow-link:hover {
  background-position: 100% 0px;
}
.sp-heading {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: 400;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #fff;
}
.about-merchant{
  p {
    color: #676767!important;
  }
}
h5,h6{
  font-weight: 500 !important;
}
h1.sp-heading {
  font-size: 28px;
  width: 100%;
  /* color: #c2a63f; */
  line-height: 1.22;
}
h2.sp-heading {
  font-size: 22px;
}
h3.sp-heading {
  font-size: 18px;
  font-weight: 300;
}
h4.sp-heading {
  font-size: 16px;
  font-weight: 500;
}
h5.sp-heading,
h6.sp-heading {
  font-size: 14px;
}
.sp-heading.gold {
  color: #c2a63f;
}
.sp-heading.center,
.sp-text.center {
  text-align: center;
}
/* .sp-subheading {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: #c2a63f;
} */

.sp-subtext {
  font-size: 12px;
  // margin: 0;
  color: #a8a8a8;
  font-weight: 300;
}
.sp-subtext2 {
  font-size: 14px;
  margin: 0;
  color: #a8a8a8;
}
.sp-subtext.white,
.sp-subtext2.white {
  color: #fff;
}

.sp-small-text {
  font-size: 9px;
  font-weight: 500;
  margin: 0;
}
.gold {
  color: #c2a63f;
}
.sp-text {
  font-size: 16px;
  color: #fff;
  margin: 0;
  font-weight: 300;
  line-height: 1.33;
  font-family: 'Open Sans', sans-serif;
}
.sp-text.light {
  font-weight: 300;
  line-height: 1.22;
}
.sp-text.bold {
  font-weight: 500;
}
.sp-text.gray {
  color: #8a8a96;
}
.sp-gold-text {
  display: block;
  font: 400 14px "Open Sans", sans-serif;
  font-size: 10px;
  color: #ebb73a;
}
.sp-gold-text.grey {
  color: #d5d5ea;
}
.sp-gold-text.hide {
  display: none;
}

.dotted-separator {
  margin: 20px 0;
  background-image: url('../../src/assets/sbic-images/icons/dot-separator.svg');
  width: 32px;
  height: 5px;
  display: block;
  background-repeat: no-repeat;
}
/* margins and padding */

.mb-xl {
  margin-bottom: 121px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-lg {
  margin-bottom: 80px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-10 {
  margin-bottom: 10px;
}
/**
     * Clear inner floats
     */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
     * Main content containers
     * 1. Make the container full-width with a maximum width
     * 2. Center it in the viewport
     * 3. Leave some space on the edges, especially valuable on small screens
     */
.container {
  max-width: 1180px;
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  padding-right: 20px;
  /* 3 */
  width: 100%;
  /* 1 */

  @media (max-width: 991px) {
    padding-left: 20px;
    /* 3 */
    padding-right: 20px;
  }
}

/**
     * Hide text while making it readable for screen readers
     * 1. Needed in WebKit-based browsers because of an implementation bug;
     *    See: https://code.google.com/p/chromium/issues/detail?id=457146
     */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
     * Hide element while making it readable for screen readers
     * Shamelessly borrowed from HTML5Boilerplate:
     * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
     */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sp-wrapper {
  width: 100%;
  /* max-width: 1130px; */
  margin: auto;
  padding: 0px 30px;
}

.flex {
  display: flex;
}
.flex.flex-sb {
  justify-content: space-between;
}
.flex.flex-ac {
  align-items: center;
}
.flex.flex-jc {
  justify-content: center;
}
.flex.flex-col {
  flex-direction: column;
}
.flex .flex-as {
  align-items: flex-start;
}
.flex .flex-je {
  justify-content: flex-end;
}

.align-bottom {
  align-self: flex-end;
}

.divider {
  background-color: #f2f2fd;
  width: 100%;
  height: 1px;
  margin: 20px 0px;
  display: block;
}
.divider.gray {
  background-color: #5d5c5c;
}
.divider.m60 {
  margin: 60px 0;
}
.sp-list {
  padding-left: 25px;
  font-size: 16px;
  margin: 0;
}
.sp-list > li {
  margin-bottom: 20px;
  font-weight: 300;
  color: #c2a63f;
}

.sp-list > li > span {
  width: 100%;
  /* color: #fff; */
  line-height: 1.13;
  letter-spacing: normal;
  color: #a8a8a8;
}

.sp-list ol {
  color: #a8a8a8;
  padding-left: 25px;
}
.sp-list > li > ol {
  list-style-type: lower-alpha;
}
.sp-list > li > ol > li > ol {
  list-style-type: lower-roman;
}
.sp-sub-list {
  color: #a8a8a8;
  list-style-type: none;
  padding-left: 25px;
}
.sp-sub-list li {
  position: relative;
  line-height: 1.13;
  letter-spacing: normal;
  color: #a8a8a8;
  font-weight: 300;
}
.sp-sub-list li:before {
  content: "";
  position: absolute;
  top: 8px;
  height: 2px;
  width: 6px;
  background: currentColor;
  left: -16px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-blue {
  color: #fff;
}

.underlined {
  text-decoration: underline;
}

.sp-pos-rel {
  position: relative;
}

.noMargin {
  margin: 0px;
}

.autoMargin {
  margin-left: auto;
  margin-right: auto;
}

.sm-hidden {
  display: inherit;
}

.full-width {
  width: 100%;
}

.sm-visible {
  display: none !important;
}

b, strong {
  font-weight: 400;
}
b.bold {
  font-weight: 600;
}
b.boldest {
  font-weight: 700;
}

.sp-illus {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.sp-illus img {
  width: 100%;
}
.sp-badge {
  border-radius: 15px;
  background-color: rgba(216, 216, 216, 0.18);
  font-size: 12px;
  padding: 5px 10px;
  color: #fff;
  display: inline-block;
  line-height: 1;
}
/* table */
.sp-table {
  border-spacing: 0px;
  border: 1px solid #444444;
  border-collapse: collapse;
}
.sp-table thead {
  border-bottom: 1px solid #444444;
}
.sp-table thead th {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  text-align: left;
  padding: 20px 10px;
  border-right: 1px solid #444444;
}
.sp-table tbody td {
  font-size: 14px;
  font-weight: 300;
  color: #8a8a96;
  text-align: left;
  padding: 10px;
  border-right: 1px solid #444444;
}
.sp-table tbody tr:first-child td {
  padding-top: 20px;
}
.sp-table tbody tr:last-child td {
  padding-bottom: 20px;
}
/* Accordion */
.sp-accordion-header {
  display: flex;
  align-items: flex-start;
  padding-bottom: 40px;
  justify-content: space-between;
}
.sp-accordion-header h4 {
  width: calc(100% - 35px);
}
.sp-accordion-header img {
  height: 14px;
  transition: transform 0.3s;
  cursor: pointer;
}
.sp-accordion-body {
  font-size: 16px;
  line-height: 1.33;
  color: #a8a8a8;
  font-weight: 300;
  height: 64px;
  overflow: hidden;
  transition: 0.4s;
}
.sp-accordion {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #363535;
}
.sp-accordion.active {
  padding-bottom: 40px;
}
.sp-accordion.active .sp-accordion-body {
  transition: 0.5s;
}
.sp-accordion.active .sp-accordion-header img {
  transform: rotate(180deg);
}
.sp-accordion.active.has-gradient .sp-accordion-body:after {
  content: none;
}
.sp-accordion.has-gradient .sp-accordion-body:after {
  content: "";
  background-image: linear-gradient(to bottom, rgba(32, 32, 32, 0) 23%, #000 77%);
  position: absolute;
  width: 100%;
  height: 105px;
  left: 0;
  bottom: 0;
}
/* dropdown button */
.sp-dropdown-button {
  min-width: 100px;
  height: 25px;
  object-fit: contain;
  border-radius: 26px;
  border: solid 1px #c2a63f;
  padding: 4px 10px;
  font-size: 12px;
  background: transparent;
  color: #fff;
  font-weight: 300;
  cursor: pointer;
  outline: none;
}
.sp-accordion-link.hidden {
  display: none;
}
.sbi-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}
.sbi-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  /* &:focus + .slider {
                                        box-shadow: 0 0 1px #000000;
                                    } */
}
.sbi-switch input:checked + .slider {
  background-color: #a8a8a8;
}
.sbi-switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
.sbi-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dadada;
  transition: 0.4s;
  border-radius: 30px;
}
.sbi-switch .slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .sp-underlined-link:before {
    transition: 0.3s;
  }
  .sp-underlined-link.active:before,
  .sp-underlined-link.active:hover:before {
    width: 100%;
  }
  .sp-underlined-link:hover::before {
    width: 0;
  }
  .mb-lg.mb-xs {
    margin-bottom: 40px;
  }
  .mb-60.mb-xs {
    margin-bottom: 20px;
  }
  .sp-list {
    font-size: 14px;
  }
  .text-center-sm {
    text-align: center;
  }
  .sm-hidden {
    display: none;
  }
  .sm-visible {
    display: block !important;
  }
  body {
    overflow-x: hidden;
  }
  .full-width-sm {
    width: 100%;
  }
  .sp-wrapper {
    padding: 0px 15px;
  }
  h1.sp-heading {
    font-size: 22px;
  }
  h2.sp-heading {
    font-size: 18px;
  }
  .divider.m60 {
    margin: 40px 0;
  }
}

@media (max-width: 767px) {
  .sp-header {
    padding: 0px 30px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* BUTTONS */
.sp-button {
  border: none;
  box-shadow: none;
  border-radius: 34px;
  font: 400 14px "Open Sans", sans-serif;
  /* width: 180px;
  height: 38px; */
  color: #2d2d2d;
  border: 1px solid transparent;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  padding: 12px 24px;
  line-height: 1;
  display: inline-block;
}
.sp-button.large {
  padding: 15px 70px;
}
.sp-button.primary {
  color: #2d2d2d;
  background-color: #c2a63f;
}
.sp-button.primary:active {
  background-color: rgba(194, 166, 63, 0.8);
}
.sp-button.primary:hover {
  /* background: #26c5ff; */
}
.sp-button.outlined {
  color: #fff;
  border: 1px solid #c2a63f;
  background-color: transparent;
}
/* .sp-button.outlined:hover {
  color: #2d2d2d;
  background-color: #c2a63f;
} */
.sp-button:disabled,
.sp-button:disabled:hover,
.sp-button[disabled],
.sp-button[disabled]:hover {
  background-color: #dadada;
  color: #fff;
  cursor: not-allowed;
}
.sp-button.noMargin {
  margin-bottom: 0px;
}

.sp-button-group {
  display: flex;
  align-items: center;
}
.sp-button-group.center {
  justify-content: center;
}
.sp-button-group .sp-button {
  margin-right: 20px;
  margin-bottom: 0px;
}
.sp-button-group.vertical {
  flex-direction: column;
  align-items: center;
}
.sp-button-group.vertical .sp-button {
  margin: 0px 0px 20px 0px;
}
.sp-button-group.space-top {
  margin-top: 40px;
}

.sp-button.outlined.rounded {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.sp-button.primary.rounded {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.space-top-40 {
  margin-top: 40px;
}

.space-top-30 {
  margin-top: 30px;
}

.space-top-20 {
  margin-top: 20px;
}

.space-top-10 {
  margin-top: 10px;
}

/* Inputs */
.sp-input-group {
  display: flex;
}
.sp-input-group.vertical {
  flex-direction: column;
}
.sp-input-group.vertical .sp-input-label:last-of-type input {
  margin-bottom: 0px;
}
.sp-input-group.vertical input {
  margin-bottom: 30px;
}

.sp-input-label {
  /* font: 400 14px "Open Sans", sans-serif; */
  /* cursor: pointer; */
  margin-bottom: 48px;
  display: block;
}

.sp-input-checkbox {
  display: flex;
}
.sp-input-checkbox input {
  width: 0px;
  height: 0px;
}

.md-checkbox {
  position: relative;
  z-index: 0;
  margin: 1em 0;
  text-align: left;
  margin-left: 30px;
}
.md-checkbox.md-checkbox-inline {
  display: inline-block;
}
.md-checkbox label:not(:empty) {
  padding-left: 0.75em;
}
.md-checkbox label:before,
.md-checkbox label:after {
  content: "";
  position: absolute;
  left: -30px;
  top: 2px;
  z-index: -1;
}
.md-checkbox label:before {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 1px;
  cursor: pointer;
  transition: background 0.3s;
}
.md-checkbox input[type="checkbox"] {
  outline: 0;
  width: 16px;
  margin: 0 10px 0 0;
  display: inline-flex;
  font-size: inherit;
  width: 0px;
  height: 0px;
  margin: 0px;
  position: absolute;
  left: -30px;
  width: 22px;
  height: 22px;
  opacity: 0;
}
.md-checkbox input[type="checkbox"]:checked + label:before {
  background: #fff;
  border: none;
}
.md-checkbox input[type="checkbox"]:checked + label:after {
  -webkit-transform: translate(3px, 5px) rotate(-45deg);
  transform: translate(3px, 5px) rotate(-45deg);
  width: 10px;
  height: 5px;
  border: 1px solid #fff;
  border-top-style: none;
  border-right-style: none;
}
.md-checkbox input[type="checkbox"]:disabled + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}
.md-checkbox input[type="checkbox"]:disabled:checked + label:before {
  background: rgba(0, 0, 0, 0.26);
}

.sp-split-input {
  position: relative;
}
.sp-split-input input {
  border: none;
  margin: 0px;
  font: 700 14px "Open Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 25.5px;
  width: 220px;
  margin-left: 20px;
  padding-left: 3px;
  outline: none;
}
.sp-split-input input + svg {
  left: 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.sp-input-text {
  position: relative;
  /* width: 180px; */
}
.sp-input-text input {
  outline: none;
  border: none;
  border-bottom: 1px solid #717171;
  color: #a8a8a8;
  padding: 0px 0px 5px 0px;
  line-height: 20px;
  width: 100%;
  background: transparent;
  transition: 0.5s;
}
.sp-input-text input:focus {
  border-color: #a8a8a8;
}
.sp-input-text input:focus + .placeholder,
.sp-input-text input.touched + .placeholder,
.sp-input-text input[value=""] + .placeholder {
  position: absolute;
  top: -16px;
  font-size: 10px;
  color: #888888;
}
.sp-input-text input.dirty:invalid + .placeholder {
  color: red;
}
.sp-input-text input:focus + .placeholder {
  color: #fff;
}
.sp-input-text input:disabled {
  color: #8a8a96;
}
.sp-input-text span.placeholder {
  position: absolute;
  top: 0px;
  font-size: 14px;
  left: 0px;
  pointer-events: none;
  transition: 0.3s;
  line-height: 20px;
  color: #8a8a96;
}
.sp-input-text.wide {
  width: 380px;
}
.sp-input-text.full {
  width: 100%;
}

/* radio button */
.sp-label-radio {
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
  margin: 0px 30px 0px 20px;
}

.sp-def-radio {
  opacity: 0;
  position: absolute;
  top: 0;
}

.sp-radio-btn {
  width: 14px;
  height: 14px;
  position: relative;
  border: 1px solid #8a8a96;
  border-radius: 50%;
}

.sp-radio-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: 0.4s;
  opacity: 0;
}

.sp-def-radio:checked ~ .sp-radio-btn {
  border: 1px solid #8a8a96;
}

.sp-def-radio:checked ~ .sp-radio-btn::before {
  opacity: 1;
}

.sp-def-radio:checked ~ .sp-radio-text {
  font-weight: bold;
  color: #2d2d2d;
}

.sp-input-text .help-text {
  font-size: 14px;
  margin-top: 10px;
}
/** Password strength **/
.sp-input-text .strength {
  font-size: 11px;
  margin-bottom: 10px;
  color: #8a8a96;
}

.sp-input-text .strength span {
  margin-left: 5px;
}

.sp-input-text .strength .red {
  color: #f74747;
}

.sp-input-text .strength .yellow {
  color: #ebb73a;
}

.sp-input-text .strength .green {
  color: #26b515;
}

.sp-select-box-main {
  width: 100%;
  /* border-bottom: 1px solid; */
  position: relative;
}

.sp-select-box {
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.sp-select-box .sp-input-label input:disabled {
  color: #2d2d2d;
}

.sp-select-box .sp-input-label {
  width: 100%;
  margin: 0;
  pointer-events: none;
}

.sp-select-box .aero {
  position: absolute;
  right: 0px;
  top: 2px;
}

.sp-dropdown-select {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
  box-shadow: 0 0 4px 0 rgba(213, 213, 234, 0.5);
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  top: 30px;
  z-index: 99;
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.sp-dropdown-select.selectOpen {
  height: auto;
  overflow: hidden;
}

.sp-select-box-item {
  font-size: 15px;
  margin-bottom: 0;
  cursor: pointer;
  padding: 12px 10px;
  color: #2d2d2d;
}

@media (max-width: 767px) {
  .sp-accordion-body {
    font-size: 14px;
  }
  .sp-button-group.vertical-sm {
    flex-direction: column;
  }
  .sp-button-group.vertical-sm .sp-button {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  .sp-button-group.vertical-sm .sp-button:last-of-type {
    margin-bottom: 0px;
  }
  .sp-button-group.vertical-sm a {
    margin: 20px 0px;
  }
  .sp-input-group.vertical .sp-input-text {
    width: 100%;
  }
  .sp-button {
    padding: 6px 10px;
    /* height: 56px; */
  }
  .sp-button.full-xs {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .sp-button-group.vertical-lg {
    flex-direction: column;
  }
  .sp-button-group.vertical-lg .sp-button {
    margin: 0px 0px 20px 0px;
  }
}

.modal-background {
  background: black;
  opacity: 0.62;
}

// .modal {
//   transform: scale(0);
//   backdrop-filter: blur(3px);
//   -webkit-backdrop-filter: blur(3px);
//   position: fixed;
//   font-family: Arial, Helvetica, sans-serif;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: rgba(0, 0, 0, 0.62);
//   z-index: 99999;
//   opacity: 0;
//   padding: 40px 0px;
//   /* transition: opacity 400ms ease-in; */
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   overflow-y: scroll;
//   overflow-y: overlay;
//   /* transition-delay: 0.3s; */
//   max-width: 100vw;
//   max-height: 100vh;
// }
// .modal .modal-body {
//   width: auto;
//   position: relative;
//   margin: auto;
//   padding: 140px 100px;
//   border-radius: 3px;
//   box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.5);
//   background-image: linear-gradient(143deg, #252525 0%, #060606 110%);
//   transition: 0.3s;
//   transition-delay: 0s;
//   -webkit-transform: scale(0);
//   transform: scale(0);
//   max-width: 538px;
// }
.modal .modal-body .modal-heading {
  /* font: 700 14px "Open Sans", sans-serif; */
  /* font-size: 28px; */
  /* color: #2d2d2d; */
  /* margin: 40px auto 20px auto; */
}
.modal .modal-body .modal-heading.subheading {
  font: 600 14px "Open Sans", sans-serif;
  font-size: 28px;
  margin: 0px 0px 20px 0px;
}
.modal .modal-body .modal-content {
  font: 400 14px "Open Sans", sans-serif;
  color: #2d2d2d;
  margin: 10px auto 10px auto;
}
.modal .modal-body .modal-content.scrollable {
  padding: 20px 10px;
  border-radius: 3px;
  border: solid 1px #f2f2fd;
  overflow-y: scroll;
  height: 340px;
  font-size: 12px;
  text-align: left;
}
.modal .modal-body .back {
  left: 100px;
  top: 50px;
  position: absolute;
}
.modal .modal-body.noPadding {
  padding: 0px;
}
.modal .modal-body .modal-padding-wrapper {
  padding: 40px 100px;
}
.modal .modal-footer {
  background: #f2f7fd;
  padding: 20px 60px;
  width: 100%;
  max-width: 590px;
}
.modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 15px;
}
.modal-close img {
  width: 100%;
}
.modal:target {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  /* transition: transform 0.5s , opacity 0.3s; */
  transition-delay: 0s;
}
.modal:target .modal-body {
  transition: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.do-body-container.do-modal-open {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
  -webkit-filter: blur(1.5px);
  filter: blur(1.5px);
}

@media (max-width: 767px) {
  .modal {
    padding: 0px;
    flex-direction: column;
    overflow: hidden;
  }
  .modal .modal-body {
    width: 100%;
    height: inherit;
    top: 0px;
    margin-top: 0px;
    padding: 25px;
    max-width: unset;
  }
  .modal-close {
    top: 25px;
    right: 25px;
  }
  .modal .modal-body .back {
    left: 20px;
  }
  .modal .modal-body .modal-heading {
    margin-top: 112px;
  }
  .modal .modal-body .modal-padding-wrapper {
    padding: 40px 20px;
  }
  .modal .modal-footer {
    position: relative;
  }
}

.sample {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.sample > span {
  font-size: 10px;
  display: block;
  color: #505050;
  font-style: italic;
}
/* .sample h4 {
  font-weight: 600;
} */
