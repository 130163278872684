@charset "UTF-8";

@font-face {
  font-family: "untitled-font-3";
  src:url("fonts/untitled-font-3.eot");
  src:url("fonts/untitled-font-3.eot?#iefix") format("embedded-opentype"),
    url("fonts/untitled-font-3.woff") format("woff"),
    url("fonts/untitled-font-3.ttf") format("truetype"),
    url("fonts/untitled-font-3.svg#untitled-font-3") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "untitled-font-3" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-3" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-group-size:before {
  content: "\61";
}
.icon-low-to-high:before {
  content: "\63";
}
.icon-high-to-low:before {
  content: "\64";
}
.icon-group-size-1:before {
  content: "\65";
}
.icon-filter-icon:before {
  content: "\66";
}
.icon-duration:before {
  content: "\67";
}
.icon-level:before {
  content: "\68";
}
.icon-price:before {
  content: "\69";
}
.icon-trip-type:before {
  content: "\6a";
}
.icon-right:before {
  content: "\6b";
}
.icon-left-arrow:before {
  content: "\6c";
}
.icon-call:before {
  content: "\6d";
}
.icon-mail:before {
  content: "\6e";
}
.icon-minus-circle:before {
  content: "\71";
}
.icon-gift-card:before {
  content: "\72";
}
.icon-cancel:before {
  content: "\73";
}
.icon-ellipsis-h:before {
  content: "\74";
}
.icon-group-1:before {
  content: "\75";
}
.icon-group:before {
  content: "\76";
}
.icon-flight:before {
  content: "\77";
}
.icon-hotels:before {
  content: "\21";
}
.icon-national-geographic:before {
  content: "\79";
}
.icon-ballon:before {
  content: "\42";
}
.icon-member:before {
  content: "\43";
}
.icon-golf:before {
  content: "\44";
}
.icon-dining:before {
  content: "\45";
}
.icon-charity:before {
  content: "\46";
}
.icon-events:before {
  content: "\47";
}
.icon-fashion:before {
  content: "\48";
}
.icon-check-circle:before {
  content: "\6f";
}
.icon-check:before {
  content: "\49";
}
.icon-spider-web:before {
  content: "\62";
}
.icon-user:before {
  content: "\4a";
}
.icon-users:before {
  content: "\4b";
}
.icon-star:before {
  content: "\4c";
}
.icon-search:before {
  content: "\4d";
}
.icon-clock:before {
  content: "\4e";
}
.icon-bars:before {
  content: "\4f";
}
.icon-minus-circle-1:before {
  content: "\50";
}
.icon-tag:before {
  content: "\52";
}
.icon-list-ol:before {
  content: "\51";
}
.icon-bed:before {
  content: "\53";
}
.icon-info:before {
  content: "\54";
}
.icon-logout:before {
  content: "\7a";
}
.icon-myaccount:before {
  content: "\41";
}
.icon-down-open-mini:before {
  content: "\55";
}
.icon-up-open-mini:before {
  content: "\56";
}
.icon-map-one:before {
  content: "\31";
}
.icon-bullet:before {
  content: "\32";
}
.icon-angle-right:before {
  content: "\70";
}
.icon-historyicon:before {
  content: "\78";
}
.icon-donation:before {
  content: "\57";
}
.icon-up-arrow:before {
  content: "\58";
}
.icon-gifting:before {
  content: "\33";
}
.icon-escapes:before {
  content: "\34";
}
.icon-airportcon:before {
  content: "\35";
}
.icon-vector-3:before {
  content: "\59";
}
.icon-bus:before {
  content: "\37";
}
.icon-trains:before {
  content: "\36";
}
.icon-shop-1:before {
  content: "\38";
}
.icon-apple:before {
  content: "\39";
}
.icon-print:before {
  content: "\5a";
}
.icon-marketing:before {
  content: "\30";
}
.icon-partner:before {
  content: "\22";
}
.icon-respect:before {
  content: "\23";
}
.icon-tick:before {
  content: "\24";
}
.icon-cursor:before {
  content: "\25";
}
.icon-warning:before {
  content: "\26";
}
.icon-o:before {
  content: "\27";
}
.icon-cart:before {
  content: "\28";
}
.icon-angle-left:before {
  content: "\29";
}
.icon-docsapp:before {
  content: "\2a";
}
.icon-birthday:before {
  content: "\2b";
}
.icon-get-together:before {
  content: "\2c";
}
.icon-wedding:before {
  content: "\2d";
}
.icon-anniversary:before {
  content: "\2e";
}
.icon-seat:before {
  content: "\2f";
}
.icon-location:before {
  content: "\3a";
}
.icon-13:before {
  content: "\3b";
}
.icon-17:before {
  content: "\3c";
}
.icon-19:before {
  content: "\3d";
}
.icon-20:before {
  content: "\3e";
}
.icon-16:before {
  content: "\3f";
}
.icon-21:before {
  content: "\40";
}
.icon-22:before {
  content: "\5b";
}
.icon-23:before {
  content: "\5d";
}
.icon-bag:before {
  content: "\5e";
}
.icon-car:before {
  content: "\5f";
}
.icon-gift:before {
  content: "\60";
}
.icon-handshake:before {
  content: "\7b";
}
.icon-porter:before {
  content: "\7c";
}
.icon-waiting-room:before {
  content: "\7d";
}
.icon-z-arrow:before {
  content: "\7e";
}
.icon-male:before {
  content: "\5c";
}
.icon-female:before {
  content: "\e000";
}
.icon-tick-1:before {
  content: "\e001";
}
.icon-circle-o:before {
  content: "\e002";
}
.icon-bookings:before {
  content: "\e003";
}
.icon-enquiries:before {
  content: "\e004";
}
.icon-paidbycard:before {
  content: "\e005";
}
.icon-paidbypoints:before {
  content: "\e006";
}
.icon-copy:before {
  content: "\e007";
}
.icon-cuisine:before {
  content: "\e008";
}
.icon-trash-o:before {
  content: "\e009";
}
.icon-round-plus:before {
  content: "\e00a";
}
.icon-round-minus:before {
  content: "\e00b";
}
.icon-circle-tick:before {
  content: "\e00c";
}
.icon-popularity:before {
  content: "\e00d";
}
.icon-pencil:before {
  content: "\e00e";
}
.icon-hamburger:before {
  content: "\e00f";
}
.icon-cake:before {
  content: "\e010";
}
.icon-digital-gift:before {
  content: "\e011";
}
.icon-flower:before {
  content: "\e012";
}
.icon-per-gift:before {
  content: "\e013";
}
.icon-plant:before {
  content: "\e014";
}
.icon-event-org:before {
  content: "\e015";
}
.icon-luxury-shop:before {
  content: "\e016";
}
.icon-pharmacy:before {
  content: "\e017";
}
.icon-special-event:before {
  content: "\e018";
}
.icon-paper:before {
  content: "\e019";
}
.icon-flower-1:before {
  content: "\e01a";
}
.icon-special-req:before {
  content: "\e01b";
}
.icon-docs-con:before {
  content: "\e01c";
}
.icon-health-well:before {
  content: "\e01d";
}
.icon-upload:before {
  content: "\e01e";
}
.icon-fitness:before {
  content: "\e01f";
}
.icon-steering:before {
  content: "\e020";
}
.icon-sleeping:before {
  content: "\e021";
}
.icon-seat-1:before {
  content: "\e022";
}
.icon-soga:before {
  content: "\e023";
}