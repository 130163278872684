body.noscroll{
    overflow: hidden;
}
/* 
    HEADER 
*/
.sp-header-container{
    background-color:rgba(17, 17, 17, 0.84);
    /* border-bottom: solid 1px #979797; */
    transition: 2s;
    &.post-login{
        background-color: #000 !important;
    }
}

.sp-header-container.floating{
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    transition: 0s;
    background-color: rgba(17, 17, 17, 0.6);
}
.sp-header-container.floating .sp-header{
    padding: 32px 30px;
    &.post-login{
        padding: 11px 30px;
    }
}
.sp-header-container.fixed{
    position: fixed;
    width: 100%;
    top: -105px;
    left: 0;
    z-index: 8;
    /* transition: 1s; */
    background-color:rgba(17, 17, 17, 0.8);
}
.sp-header-container.fixed.show{
    top: 0;
    transition: .8s;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
}
.sp-header-container.floating #apply-header-cta{
    display: none;
}

.sp-header-container.floating.fixed #apply-header-cta{
    display: inherit;
}
.sp-header-container.fixed.floating .sp-header{
    padding: 32px 30px;
    &.post-login{
        padding: 11px 30px;
    }
}

.sp-header{
    /* padding: 50px 30px 0 30px; */
    padding: 32px 30px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.sp-header.floating{
    padding: 32px 30px;
}
.sp-header-logo{
    display: inline-block;
    height: auto;
    /* min-width: 100px; */
    position: relative;
}
.sp-header-logo img{
    height: 100%;
    width: auto;
    height: 24px;
    display: inline-block;
}
.sp-navigation ul{
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;    align-items: flex-start;
    margin-left: 25px;
}
.sp-navigation ul li{
    padding: 0 15px;
    color: #a8a8a8;
    line-height: 0;
}

.sp-navigation ul li a{
    color: inherit;
    display: inline-block;
    position: relative;
    text-decoration: none !important;
    font-size: 12px;
    padding-bottom: 4px;
    overflow: hidden;
    line-height: 1em;
}

.sp-navigation ul li a:before{
    content: '';
    position: absolute;
    bottom: 0px;
    left: -100%;
    width: 100%;
    height: 1px;
    transition: left .5s;
    background-color: #c2a63f;
}
.sp-navigation ul li.active{
    color: #fff;
}
.sp-navigation ul li.seperate-link:hover{
    color: #c2a63f;
}
.sp-navigation ul li.seperate-link:hover a::before{
    width: 0;
    height: 0;
}
.sp-navigation ul li.active > a:before,
.sp-navigation ul li a:hover:before{
   left: 0;
}
.sp-popover-wrapper,.sp-popover-wrapper > a{
    position: relative;
    cursor: default;
}
.sp-navigation ul li.sp-popover-wrapper a:before{
    content: none;
}
ul.sp-popover{
    position: absolute;
    top: calc(100% + 10px);
    left: 15px;
    width: 218px;
    /* height: 200px; */
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    background-color: #1f1f1f;
    font-size: 12px;
    padding: 20px;
    text-align: l;
    margin: 0; 
    display: block;
    transform: scaleY(0);
    transition: transform 0.3s 0.2s;
    transform-origin: top;
}

ul.sp-popover::before{
    position: absolute;
    content: '';
    border-left: 12px solid #1e1e1e;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    width: 0;
    display: block;
    height: 0;
    /* background: black; */
    transform: rotate(-90deg);
    /* background: white; */
    top: -17px;
}

ul.sp-popover li{
    padding: 0;
    margin-bottom: 20px;
    opacity: 0;
    transition: opacity .3s ;
}
ul.sp-popover li:last-child{
    margin-bottom: 0;
}
.sp-popover li span{
    color: #717171;
    margin-bottom: 10px;
    display: block;
}

.sp-popover li a{
    color: #fff;
    width: 100%;
    text-decoration: underline;
}
.mobile-links{
    display: none;
}
.mobile-links.gold{
    color: #c2a63f;
}
.sp-user-options,.sp-user-logged-options{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: auto;
}
.sp-user-options .sp-login{
    margin-left: 20px;
    display: inline-block;
}
.sp-user-logged-options .user-name{
    font-size: 12px;
    color: #fff;
}
.sp-user-logged-options .user-points{
    color: #c2a63f;
    font-size: 12px;
}
.sp-user-logged-options .dashboard-button{
    margin: 0 15px;
}
.sp-user-logged-options .dashboard-button button{
    padding: 12px 17px;
    font-size: 12px;
}
.sp-user-logged-options .logout a{
    font-size: 12px;
    color: #a8a8a8;
    text-decoration: underline;
}
.sp-user-logged-options .seperator{
    height: 13px;
    width: 1px;
    margin: 0 10px;
    background: #fff;
}
.sp-header.user-logged-in .sp-user-options{
    display: none;
}.sp-header.user-logged-in  .sp-user-logged-options{
    display: flex;
}
.sp-header .sp-user-options{
    display: flex;
}.sp-header .sp-user-logged-options{
    display: none;
}

.sp-header.user-logged-in .user,
.sp-header.user-logged-in .guest{
    display: none;
}

/* 
    SECTION
*/
.wrapper-padding{
    padding-left: 125px;
    padding-right: 125px;
}
.sp-section{
    padding: 100px 125px;
    /* min-height: 80vh; */
    background: #fff;
    position: relative;
    // overflow-x: hidden;
}
.sp-section.padding-160{
    padding-top: 160px;
    padding-bottom: 160px;
}
.backdrop-circled{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
}
.sp-section.extra-padding{
    padding-top: 200px;
}
/* .sp-section.full{
    min-height: calc(100vh - 100px);
    min-height: 100vh;
} */
.sp-section.center{
    display: flex;
    align-items: center;
}
.sp-section.light{
    background: #fff;
    color: #000;
}
.sp-section.dark-solid{
    background: rgba(0, 0, 0, 1);
}
.sp-section.dark{
    background: #252525;
    background-image: linear-gradient(to top, #252525 0, #060606 30%);
    color: #fff;
}
.sp-section.dark2{
    background-image: linear-gradient(121deg, #252525 -5%, #000000 31%);
}
.sp-section.dark3{
    background-image: linear-gradient(74deg, #252525 -68%, #060606 97%);
}
.sp-section.dark4{
    background-image: linear-gradient(to top, #1a1a1a 0, #000000 30%);
}.sp-section.dark5{
    background-image:  linear-gradient(114deg, #252525 -16%, #000000 114%);;
}
.sp-section.dark6{
    background-image: linear-gradient(123deg, #0f0f0f 75%, #1a1a1a 101%);
}
.sp-section.dark7{
    background-image: linear-gradient(to top, #060606 0%, #1c1c1c, #1c1c1c);
}
.sp-section.dark8{
    background-image: linear-gradient(to top, #1a1a1a 88%, #000000 12%);
}
.sp-section.dark9{
    background-image: linear-gradient(89deg, #0f0f0f 25%, #1a1a1a 51%);
}
.sp-section.dark10{
    background-image: linear-gradient(179deg, #252525 0%, #000000 57%);
}
.sp-section.grey{
    background: #f8f8f8;
    color: #000;
}
.sp-section-list,
.sp-section-list.horizontal{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-right: -28px;
    margin-left: -28px; 
    list-style: none;
}
/* .sp-section-list.horizontal .sp-section-list-item{
    display: flex;
} */
.sp-section-list.half{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-right: -28px;
    margin-left: -28px;
    padding: 80px 100px 0 100px;
}

.sp-section-list.half .sp-section-list-item{
    width: 50%;
    margin-bottom: 60px;
}

.sp-section-list.half .sp-section-list-item:nth-last-child(2),
.sp-section-list.half .sp-section-list-item:last-of-type{
    margin-bottom: 0;
}
.sp-section-list.vertical{
    flex-direction: column;
}
.section-center{
    display: flex;
    flex-direction: column;
    min-height: calc(90vh - 200px);
    align-items: flex-start;
    justify-content: center;
}
.section-content-center{
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    margin: auto;
}
.sp-section-list-item.side-circle{
    padding-left: 110px;
    position: relative;
}

.sp-section-list-item.side-circle .circle-gradient{
    position: absolute;
    left: 20px;
    top: 0;
}

/* Image general */
.sp-image{
    width: 100%;
}
.circle-gradient{
    height: 31px;
    width: 31px;
    background-image: linear-gradient(to bottom, #d7c36b, #9f7e2e);
    display: inline-block;
    border-radius: 50%;
    position: relative;
}

.circle-gradient::before{
    content: '';
    background: black;
    border-radius: 50%;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 2px;
    left: 2px;
}

/* Side Carousel Half Caoursel */
.sp-side-carousel{
    display: flex;
    align-items: center;
    justify-content: center;  
    /* height: 100%; */
    transform: translateY(-50%);
    position: relative;
    top: 50%;
    flex-wrap: wrap;
}
.sp-side-carousel h2{
    width: 100%;
}

.sp-side-carousel .glider-slide{
    opacity: 0;
    transition: 0s;
}
.sp-side-carousel .glider-slide.center,
.sp-side-carousel .glider-slide.visible{
    opacity: 1;
    transition: 1s;
}
/* dots */
.sp-side-carousel .glider-dots,.glider-dots{
    position: absolute;
    bottom: -40px;
    left: -7px;
}
.sp-side-carousel .glider-dot, .glider-dot{
    border: 2px solid #fff;
    margin: 5px;
    background-color: transparent;
}
.glider{
    width: 100%;
}
.glider::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  
.glider::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  
.glider::-webkit-scrollbar-thumb {
    background: transparent; 
  }
  
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */
.sp-side-carousel .glider-dot.active, .glider-dot.active{
    background-color: #fff;
}

/* transitions */
.transition-up{
    transform: translateY(50px);
    transition: 1.5s;
    opacity: 0;
}
.transition-up.in{
    opacity: 1;
    transform: translateY(0);
}


/* footer */

.sp-footer{
    padding-top: 50px;
    padding-bottom: 0px;
    color: #fff;
    background: #000;
    position: relative;
}
.footer-list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
}
.footer-list li{
    margin-bottom: 10px;
}
.footer-list li a{
    font-size: 12px;
    color: white;
}
.footer-list li a:hover{
    text-decoration: underline;
}

.sp-footer .sp-icon-link:hover,
.sp-footer .sp-icon-link{
    font-size: 12px;
}
.powered-by{
    margin-top: 25px;
}
.powered-by img{
    height: 23px;
}
.bottom-footer{
    margin-top: 50px;
    padding: 27px 27px 17px 27px;
    background: #1a1a1a;
    position: relative;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
}
.bottom-footer ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}
.bottom-footer ul li {
    font-size: 9px;
    padding: 0 5px;
    border-right: 1px solid #b5b8b7;
    color: #b5b8b7;
    line-height: 1.33;
}
.bottom-footer ul li:last-child {
    border: none;
}
.bottom-footer ul li a{
    font-size: 9px;
    color: #b5b8b7;
}
.bottom-footer ul li a:hover{
    text-decoration: underline;
}
.bottom-footer p{
    color: #b5b8b7;
}
/* Video */
.full-bleed-video{
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    object-fit: cover;
    height: auto;
    width: 100%;
    z-index: 0;
}



.z-top{
    z-index: 1;
}

.visible-md,.visible-sm{
    display: none !important;
}
.hidden-sm{
    display: initial !important;
}
.sp-header-ham{
    display: none;
}
.ham-logo-block{
    display: none;
}
.modal-extra-links p{
margin-bottom: 13px;
}
#login .modal-logo{
    display: none;
}
#footer-cta .sp-icon-link span{
    margin-left: 5px;
}
.collapse-contact-link{
    display: none;
}

.user-mobile-block{
    display: none;
}
.scroll-to-top.active{
	opacity: .6;
	transition: .5s;
	display: inline-block;
}
.scroll-to-top{
	width: 50px;
    height: 50px;
    position: fixed;
    bottom: 30px;
    right: 35px;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	transition: 0.2s;
	display: none;
	z-index: 9;
}
.scroll-to-top:hover{
	opacity: 1;
}
.scroll-to-top svg{
	width: 40px;
	height: 40px;
}
/* Media Queries */

@media only screen and (min-width: 992px) and (max-width: 1024px){
    .sp-navigation ul li{
        padding: 0 12px;
    }
}
@media (min-width:1201px){
    .sp-popover-wrapper:hover ul.sp-popover{
        transform: scaleY(1);
        transition: transform 0.3s;
        z-index: 1;
    }
    .sp-popover-wrapper:hover ul.sp-popover li{
        opacity: 1;
        transition: opacity .3s .3s;
    }
}
@media only screen and (max-width: 991px){
   
    .visible-md{
        display: unset;
    }

    #footer-cta{
        margin-top: 40px;
    }
    
    
}
@media (max-width:1200px){
    .sp-navigation ul li{
        color: #a8a8a8;
        border-color: #717171;
    }
    .sp-navigation ul li.active{
        border-color: #717171;
    }
    .sp-popover-wrapper.active .collapse-contact-link{
        transform: rotate(0deg);
    }
    .user-logged-in .user-mobile-block{
        background-color: #000;
        padding: 20px 28px;
        margin-left: -28px;
        margin-right: -28px;
        width: calc(100% + 56px);
        /* margin-top: 25px; */
        display: block;
        transition: .5s;
        opacity: 0;
        transform: translateY(-20px);
    }
    .user-logged-in .user-mobile-block.fadein{
        opacity: 1;
        transform: translateY(0px);
    }
    .user-mobile-block .user-name{
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
    }
    .user-mobile-block #mobile-dashboard-cta{
        font-size: 12px;
        padding: 4px 10px;
    }
    .user-mobile-block .user-points{
        color: #c2a63f;
        font-size: 14px;
    }
    .sp-user-logged-options .dashboard-button,
    .sp-user-logged-options .logout{
        display: none;
    }
    .sp-header-ham{
        display: inline-block;
        margin-left: 20px;
    }
    .ham-logo-block{
        display: flex;
        align-self: center;
        margin-bottom: 25px;
        justify-content: space-between;
    }
    .sp-header-container{
        z-index: 8;
    }
    .sp-navigation{
        position: fixed;
        display: flex;
        width: 100vw;
        flex-wrap: wrap;
        height: 100vh;
        top: 0px;
        left: 0;
        background: #1a191d;
        padding: 27px;
        transform-origin: top;
        transform:  scaleY(0);
        transition: .3s;
        display: none;
    }
    .sp-navigation.in{
        display: block;

    }

    .sp-navigation.active{
        transform:  scaleY(1);
        transition: .3s;
        z-index: 1;
    }

    .sp-navigation .sp-header-logo, .sp-navigation  .sp-header-cross{
        opacity: 0;
        /* transition: .1s; */
        height: 16px;
    }
    .sp-header-logo.fade-in,.sp-header-cross.fade-in{
        opacity: 1;
    }
    .sp-navigation ul {
        margin: 0;
        height: calc(100% - 80px);
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }
    .sp-navigation ul li{
        padding: 15px 0;
        border-bottom: 1px solid #717171;
        transform: translateY(-25px);
        transition: .3s;
        opacity: 0;
    }
    .sp-navigation ul li.loaded{
        opacity: 1;
        transform: translateY(0px);
    }
    .sp-navigation ul li a{
        width: 100%;
        height: 100%;
        display: block;
        border: none;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sp-navigation ul li a::before{
        content: none;
    }

    
    .sp-header.user-logged-in .sp-navigation ul li.guest.mobile-links{
        display: none;
    }
    .sp-header .sp-navigation ul li.user{
        display: none;
    }

    .sp-navigation ul li.mobile-links,
    .sp-header.user-logged-in .sp-navigation ul li.user{
        display: block;
        border-color: #717171;
    }
    .sp-header-container.floating .sp-header{
        padding: 10px 30px 10px 30px;
    }
    .sp-navigation ul li.seperate-link{
        border-color: #717171;
    }
    .sp-navigation ul li.seperate-link a{
        max-height: 16px;
        color: #a8a8a8;
    }
    .sp-navigation ul li.seperate-link.active a{
        
        color: #c2a63f;
    }
    ul.sp-popover{
        position: relative;
        height: auto;
        top: 16px;
        bottom: unset;
        display: block;
        left: 0;
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 100%;
        transform: scale(1);
        height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: .5s;
    }
    ul.sp-popover::before{
        content: none;
    }
    .sp-popover-wrapper.active .sp-popover{
        transform: scale(1);
        height: auto;
        margin-bottom: 15px;
    }
    .sp-popover-wrapper.active .sp-popover li{
        opacity: 1;
        transform: translateY(0);
        transition: .5s;
    }
    ul.sp-popover li{
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
    }
    ul.sp-popover li span{
        font-size: 14px;
        margin-bottom: 0;
    }
    ul.sp-popover li a{
        width: auto;
        margin-left: 10px;
    }
    .collapse-contact-link{
        display: inline-block;
        transform: rotate(180deg);
    }
}
@media (min-width:768px) and (max-width: 1200px){
    .sp-navigation{
        padding: 32px 60px;
    }
    .user-logged-in .user-mobile-block{
        margin-left: -60px;
        margin-right: -60px;
        width: calc(100% + 120px);
        padding: 20px 60px;
    }
}
@media only screen and (max-width: 1024px){
   
    .sp-section{
        padding: 80px 30px;
    }
    .wrapper-padding{
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media only screen and (max-width: 767px){
    .bottom-footer ul li, 
    .bottom-footer ul li a,
    .bottom-footer p{
        font-size: 12px;
    }
    .powered-by{
        margin-top: 30px;
        flex-direction: column;
    }
    .bottom-footer{
        padding-bottom:25px;
    }
    .footer-list li{
        margin-bottom: 0;
        line-height: 1.33;
    }
    .bottom-footer ul{
        margin-bottom: 35px;
    }
    .sp-section.dark2{
        background-image: linear-gradient(176deg, #252525 -1%, #000000 10%);
    }
    .sp-section.padding-160{
        padding: 80px;
    }
    .visible-sm{
        display: initial !important;
    }
    .visible-sm.visible-flex{
        display: flex !important;
    }
    .hidden-sm{
        display: none !important;
    }
    .sp-header-container .sp-header {
        padding: 25px 0;
        max-height: 68px;
    }
    .sp-header-container.floating .sp-header{
        // padding: 30px 0 10px 0;
        padding: 20px 0 20px 0;
        &.post-login{
            padding: 10px 0 10px 0;
        }
    }
    .sp-header-container.fixed.floating .sp-header
    {
        padding: 20px 0 20px 0;
        &.post-login{
            padding: 11px 0px;
        }
    }
    .sp-section{
        padding: 80px 0px;
    }
    .sp-section.extra-padding{
        padding-top: 115px;
    }
    .sp-header-logo img{
        height: 17px;
    }
    #apply-header-cta,#login-header-cta{
        font-weight: 400;
    }
    #login-header-cta{
        margin-left: 10px;
    }
    .apply-image{
        order: 2;
        margin-top: 40px;
    }
    .apply-content{
        order: 1;
        text-align: center;
    }
    .sp-section-list-item.side-circle{
        padding-left: 0;
        padding-top: 50px;
    }
    .sp-section-list-item.side-circle .circle-gradient{
        left: 0;
    }
    .margin-collapse-sm{
        margin: 0 ;
    }
    .card-apply-banner,.card-apply-banner.sp-section{
        padding-bottom: 0;
        align-items: stretch;
        padding-top: 100px;
    }
    .card-apply-banner .row{
        height: 100%;
    }
    .card-apply-banner .sp-wrapper{
        margin: unset;
    }
    .sp-footer .wrapper-padding{
        padding: 0;
    }
    .sp-footer .row{
        margin-left: -6px;
        margin-right: -6px;
    }
    .sp-footer .col-md-4{
        width: auto;
        flex: 1;
        /* width: 33.33%; */
        padding: 0 6px;
    }
    #footer-cta{
        padding: 0;
    }
    #login .modal-logo{
        display: inline-block;
        height: 15px;
    }
    .modal-extra-links{
        text-align: center;
    }
    .login-form .sp-button{
        margin-left: 50%;
    transform: translateX(-50%);
    }
}
@media (max-width:991) and (min-width: 990px){
    .sp-navigation{
        display: none;
    }
}
@media (orientation: landscape){
    .sp-section.full{
        min-height: calc(100vh - 100px);
        min-height: 90vh;
    }
    .sp-section.full-90{
        min-height: calc(90vh - 100px);
        min-height: 90vh;
    }
}
@media (min-width:768px){
    #login .modal-body{
        width: 538px;
    }
}
@media (max-width:575px){
    .login-form .sp-button{
      width: 100%;
    }
}
@media (max-width: 350px){
    #apply-header-cta,#login-header-cta{
        display: none;
    }
}