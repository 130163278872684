@import "@angular/material/theming";
@import "./fontastic-font.css";
@import "react-dates/lib/css/_datepicker.css";
@import "./theme/three-dots.css";
@import "sweetalert2/src/sweetalert2.scss";

// @import '~bootstrap/scss/_variables.scss';
// Plus imports for other components in your app.
$custom-typography: mat-typography-config(
  $font-family: "'Open Sans', sans-serif",
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rewards-hub-primary: mat-palette($mat-indigo);
$rewards-hub-accent: mat-palette($mat-pink, A200, A100, A400);
// $font-family-base:$font-family;
// The warn palette is optional (defaults to red).
$rewards-hub-warn: mat-palette($mat-red);
// $wrapper-padding-bottom:0px;

// Create the theme object (a Sass map containing all of the palettes).
$rewards-hub-theme: mat-light-theme($rewards-hub-primary, $rewards-hub-accent, $rewards-hub-warn);
@include angular-material-theme($rewards-hub-theme);

html,
body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

.cdk-overlay-pane {
  // margin-top: 50px;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.bg {
  background-color: var(--background);
}

.w100 {
  width: 100%;
}

.w85 {
  width: 85%;
}

.h100 {
  height: 100%;
}

.mat-drawer-content {
  background: var(--background);
  overflow: hidden !important;
}
.mat-dialog-container {
  background-color: var(--blacktwo);
  padding: 0px !important;
  border-radius: 8px !important;
  border: 1px solid var(--primary);
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}
// mat-button-typography
.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: var(--accent) !important;
  border-width: 1px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  color: var(--accent);
  background-color: var(--primary) !important;
  transform: scale(0.6) !important;
}

// mat-icon-stepper selected color change
// ::ng-deep .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
//     background-color: red!important;
// }
// ::ng-deep .mat-select-arrow {
//     display: none;
// }
//input outline color
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: transparent !important;
  background: var(--background) !important;
  // opacity: 1!important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: none !important;
  background: var(--background) !important;
  // opacity: 1!important;
}
//mat-input focused color
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--accent) !important;
}

// mat-input error outline color
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--accent) !important;
  opacity: 0.8 !important;
}

// mat-input carent color
.mat-input-element {
  caret-color: var(--accent) !important;
}

// mat-input error carent color
.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: var(--accent) !important;
}

// mat-label 300 state style
.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6) !important;
  // color: $mainColor!important;
}

// mat-label focused style
.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--accent) !important;
}

// mat-label error style
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--accent) !important;
}

.mat-select-arrow {
  border: none !important;
}

// :host ::ng-deep {
.my-custom-component-small {
  // add my-custom-component-small class to your mat-form-field element
  font-size: 0.8125em;
  .mat-form-field-flex > .mat-form-field-infix {
    padding: 0.4em 0 !important;
    color: var(--black);
  }
  .mat-form-field-label-wrapper {
    top: -1.5em;
  }
  &.mat-form-field-appearance-outline .mat-form-field-outline {
    background: inherit !important;
    color: var(--greythree) !important;
  }
  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    // transform: translateY(-1.1em) scale(.75);
    // width: 133.33333%;
    color: var(--black);

    // background: inherit;
  }
  // &.mat-form-field-appearance-outline .mat-form-field-outline-end {
  //   border-radius: 20px !important;
  // }
  // &.mat-form-field-appearance-outline .mat-form-field-outline-start {
  //   border-radius: 0px !important;
  //   // display: none;
  // }
}

.custom-select-dropdown {
  // font-size: 0.8125em;
  .mat-form-field-underline {
    display: none;
  }
  .myPanelClass {
    margin-top: 80px !important;
  }
  // .mat-form-field-flex > .mat-form-field-infix { padding: 0.4em 0 !important;}
  .mat-form-field-label-wrapper {
    top: -1.5em;
  }
  &.mat-form-field-appearance-outline .mat-form-field-outline {
    background: inherit !important;
  }
}

.mat-slider.mat-slider-horizontal {
  min-width: calc(100% - 1rem);
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: var(--primary);
}
.mat-accent .mat-slider-thumb {
  height: 16px;
  width: 16px;
  background-color: var(--primary);
  // border: solid 2px gray;
  bottom: -8px;
  right: -8px;
}
// }

.mat-checkbox {
  .mat-checkbox-layout {
    width: 100%;
    .mat-checkbox-label {
      margin-right: auto;
    }
    .mat-checkbox-inner-container {
      margin-right: 8px;
    }
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--primary) !important;
}

.mat-ripple-element {
  background: var(--primary) !important;
}

.ng5-slider {
  margin: 20px 0 24px !important;
  .ng5-slider-pointer {
    //.ng5-slider-pointer-active:after{
    background: var(--primary) !important;
    width: 16px !important;
    height: 16px !important;
    top: -7px !important;
    outline: none;
    z-index: 1 !important;
  }
  .ng5-slider-bubble {
    &.ng5-slider-floor {
      display: none !important;
    }
    &.ng5-slider-ceil {
      display: none !important;
    }
    &.ng5-slider-model-value {
      display: none !important;
    }
    &.ng5-slider-model-high {
      display: none;
    }
    &.ng5-slider-combined {
      display: none;
    }
  }
  .ng5-slider-pointer::after {
    width: 0 !important;
    height: 0 !important;
  }
  .ng5-slider-bubble {
    font-size: 0.875em !important;
    font-weight: 400;
  }
  .ng5-slider-bar {
    &.ng5-slider-selection {
      background: var(--primary) !important;
    }
  }
  .ng5-slider-tick {
    .ng5-slider-tick-value {
      background: black;
    }
  }
}
.custom-input {
  border: none;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #e3e5e7;
  border-radius: 20px;
  .is-medium {
    font-size: 13px;
    line-height: 19px;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: var(--black);
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    opacity: 1;
    color: var(--black);
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    opacity: 1;
    color: var(--black);
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--black);
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--black);
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    color: var(--black);
  }
  .iconc {
    float: left;
    padding: 0.2em 0.5em;
  }
  .custom {
    border: none;
    float: left;
    font-size: 0.8125em;
    &:focus {
      outline: none;
    }
  }
}
.mat-radio-label-content,
.mat-checkbox-label {
  white-space: 300;
  word-break: break-word;
  color: var(--accent);
  font-weight: 300;
}

.rupee-symbol {
  font-family: sans-serif;
}

.pos-a {
  position: absolute;
}

.pos-r {
  position: relative;
}

.ft-wt-600 {
  font-weight: 500 !important;
}

.ft-wt-500 {
  font-weight: 400 !important;
}

.f500 {
  font-weight: 400 !important;
}

.ft-wt-400 {
  font-weight: 300 !important;
}

.primary-btn {
  background-color: var(--primary);
  color: var(--secblack);
}

// .primary-btn:hover {
//   background-color: var(--hover);
// }

.map-image {
  padding: 13px 20px;
  position: fixed;
  bottom: 0;
  background-color: var(--background);
  z-index: 1;
  box-shadow: 0px -0.5px 2px rgba(0, 0, 0, 0.12);
  &.custom-center {
    left: 0;
    text-align: center;
  }
}

input {
  border: none;
  outline: none;
}

.font-14 {
  font-size: 14px;
}

.option-item-inner {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  color: var(--accent);
  display: block;
  &:hover {
    background-color: var(--background);
  }
  @media (max-width: 576px) {
    font-size: 14px;
    width: 100%;
  }
}

.dropdown-menu {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  background: var(--blacktwo);
  font-weight: 300 !important;
}

input {
  border: none;
  outline: none;
}

.cursor {
  cursor: pointer;
}

hr {
  margin: 0 !important;
}

.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 14px;
}

.ft-wt-300 {
  font-weight: 300;
}

.ft-wt-600 {
  font-weight: 600;
}
.ft-sz-16 {
  font-size: 16px;
}

.ft-sz-12 {
  font-size: 12px;
}
.ft-sz-14 {
  font-size: 14px;
}

.nat-geo-listing-tabs {
  position: relative;
  z-index: 2;
  .tabs {
    position: fixed;
    top: 90px;
  }
}
.flight-single-date {
  .SingleDatePicker {
    width: 100%;
    margin-top: 15px;
  }
  .SingleDatePickerInput {
    border: 0;
    background-color: var(--background);
    border-radius: 4px;
  }
  .DateInput {
    background-color: var(--background);
    width: 100%;
    padding: 6px 8px;
  }
  .DateInput__display-text {
    font-size: 14px;
  }
}
.nat-geo-navbar-fixed-top {
  position: fixed !important;
  top: 54px;
  left: 0;
  background: var(--blacktwo);
  z-index: 1;
  justify-content: unset !important;
  overflow-x: auto;
  @media (min-width: 576px) {
    margin-top: 10px;
  }
  .tab-item {
    padding: 0 10px;
  }
}

.hotel-navbar-fixed-top {
  position: fixed !important;
  top: 0; //14vh;
  margin-top: 146px;
  left: 0;
  background: var(--blacktwo);
  z-index: 1;
  justify-content: unset !important;
  overflow-x: auto;
  // @media (max-width: 768px) {
  //   // margin-top: 10px;
  //   top: 4vh;
  // }
  // @media (max-width: 576px) {
  //   // margin-top: 10px;
  //   top: 7vh;
  // }
  // @media (max-width: 360px) {
  //   // margin-top: 10px;
  //   top: 11vh;
  // }
  // @media (max-width: 320px) {
  //   // margin-top: 10px;
  //   top: 14vh;
  // }
  .tab-item {
    padding: 0 10px;
  }
}

//Framework-Amit common Start
.radio {
  position: relative;
  line-height: 1.5rem;
  display: block;
  input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    box-sizing: border-box;
    padding: 0;
  }
  .radio__label {
    position: relative;
    min-width: 18px;
    min-height: 18px;
    padding-left: 27px;
    text-align: left;
    margin: 0;
    color: var(--accent);
  }
  input[type="radio"]:checked ~ .radio__label:before {
    border-color: var(--accenttwo);
    background: inherit; //var(--background);
  }
  .radio__label:before {
    content: "";
    border: 1px solid var(--accenttwo);
    background-color: inherit; //var(--background);
    transition: border-color 0.2s, background-color 0.2s;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 1px;
    border-radius: 50%;
  }
  [type="radio"]:checked + label:after {
    top: 5px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: var(--primary);
  }
  .radio__label:after {
    position: absolute;
    content: "";
    border-radius: 50%;
    transition: transform 150ms, opacity 150ms, -webkit-transform 150ms;
  }
}

.default-dropdown-menu {
  background: var(--blacktwo);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;
  padding: 8px;
  .option {
    padding: 7px 15px;
    color: var(--accent);
  }
  .option:hover,
  .option:focus {
    background-color: var(--background);
    color: var(--accent);
  }
}
.inline-block {
  display: inline-block;
}
.ln-20 {
  line-height: 20px;
}
.ln-21 {
  line-height: 21px;
}
.ln-22 {
  line-height: 22px;
}
.ln-23 {
  line-height: 23px;
}
.ln-24 {
  line-height: 24px;
}
.ln-25 {
  line-height: 25px;
}
.ln-26 {
  line-height: 26px;
}
.ln-27 {
  line-height: 27px;
}
.ln-28 {
  line-height: 28px;
}
.ln-29 {
  line-height: 29px;
}
.ln-30 {
  line-height: 30px;
}
.ln-31 {
  line-height: 31px;
}
.ln-32 {
  line-height: 32px;
}
.ln-33 {
  line-height: 33px;
}
.ln-34 {
  line-height: 34px;
}
.ln-35 {
  line-height: 35px;
}
.ln-36 {
  line-height: 36px;
}
.ln-37 {
  line-height: 37px;
}
.ln-38 {
  line-height: 38px;
}
.ln-39 {
  line-height: 39px;
}
.ln-40 {
  line-height: 40px;
}
.ln-41 {
  line-height: 41px;
}
.ln-42 {
  line-height: 42px;
}
.ln-43 {
  line-height: 43px;
}
.ln-44 {
  line-height: 44px;
}
.ln-45 {
  line-height: 45px;
}
.z1 {
  z-index: 1;
}
.w100 {
  width: 100%;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.bg-fff {
  background-color: var(--blacktwo);
}
.cfff {
  color: #fff;
}
.popover-body {
  padding: 4px 15px 6px;
  border-radius: 3px;
  font-size: 12px;
  background: var(--blacktwo);
  color: var(--accent);
}

.bs-popover-top > .arrow::after {
  border-top-color: var(--blacktwo);
}
.bs-popover-bottom > .arrow::after {
  border-bottom-color: var(--blacktwo);
}
.bs-popover-right > .arrow::after {
  border-right-color: var(--blacktwo);
}
.bs-popover-left > .arrow::after {
  border-left-color: var(--blacktwo);
}

.bs-popover-top > .arrow::before {
  border-top-color: var(--primary);
}
.bs-popover-bottom > .arrow::before {
  border-bottom-color: var(--primary);
}
.bs-popover-right > .arrow::before {
  border-right-color: var(--primary);
}
.bs-popover-left > .arrow::before {
  border-left-color: var(--primary);
}
.object-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hide {
  display: none;
}
.show {
  display: block !important;
}
.pointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
.inline-block {
  display: inline-block;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.page-link {
  margin: 0 4px !important;
}

button:focus {
  outline: none;
}

.left {
  float: left;
}
.f300 {
  font-weight: 300;
}
.f400 {
  font-weight: 400;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.fnt-9 {
  font-size: 9px;
}
.fnt-10 {
  font-size: 10px;
}
.fnt-11 {
  font-size: 11px;
}
.fnt-12 {
  font-size: 12px;
}
.fnt-14 {
  font-size: 13px;
}
.fnt-14 {
  font-size: 14px;
}
.fnt-15 {
  font-size: 15px;
}
.fnt-16 {
  font-size: 16px;
}
.fnt-17 {
  font-size: 17px;
}
.fnt-18 {
  font-size: 18px;
}
.fnt-19 {
  font-size: 19px;
}
.fnt-20 {
  font-size: 20px;
}
.fnt-21 {
  font-size: 21px;
}
.fnt-22 {
  font-size: 22px;
}
.fnt-23 {
  font-size: 23px;
}
.fnt-24 {
  font-size: 24px;
}
.fnt-25 {
  font-size: 25px;
}
.fnt-26 {
  font-size: 26px;
}
.fnt-27 {
  font-size: 27px;
}
.fnt-28 {
  font-size: 28px;
}
.fnt-29 {
  font-size: 29px;
}
.fnt-30 {
  font-size: 30px;
}
.fnt-31 {
  font-size: 31px;
}
.fnt-32 {
  font-size: 32px;
}
.fnt-33 {
  font-size: 33px;
}
.fnt-34 {
  font-size: 34px;
}
.fnt-35 {
  font-size: 35px;
}
.fnt-36 {
  font-size: 36px;
}
.fnt-37 {
  font-size: 37px;
}
.fnt-38 {
  font-size: 38px;
}
.fnt-39 {
  font-size: 39px;
}
.fnt-40 {
  font-size: 40px;
}
.h100 {
  height: 100%;
}
.mar-15 {
  margin: 15px;
}
.mar-30 {
  margin: 30px;
}
.mar-top-1 {
  margin-top: 1px;
}
.mar-top-2 {
  margin-top: 2px;
}
.mar-top-3 {
  margin-top: 3px;
}
.mar-top-4 {
  margin-top: 4px;
}
.mar-top-5 {
  margin-top: 5px;
}
.mar-top-6 {
  margin-top: 6px;
}
.mar-top-7 {
  margin-top: 7px;
}
.mar-top-8 {
  margin-top: 8px;
}
.mar-top-9 {
  margin-top: 9px;
}
.mar-top-10 {
  margin-top: 10px;
}
.mar-top-11 {
  margin-top: 11px;
}
.mar-top-12 {
  margin-top: 12px;
}
.mar-top-13 {
  margin-top: 13px;
}
.mar-top-14 {
  margin-top: 14px;
}
.mar-top-15 {
  margin-top: 15px;
}
.mar-top-16 {
  margin-top: 16px;
}
.mar-top-17 {
  margin-top: 17px;
}
.mar-top-18 {
  margin-top: 18px;
}
.mar-top-19 {
  margin-top: 19px;
}
.mar-top-20 {
  margin-top: 20px;
}
.mar-top-21 {
  margin-top: 21px;
}
.mar-top-22 {
  margin-top: 22px;
}
.mar-top-23 {
  margin-top: 23px;
}
.mar-top-24 {
  margin-top: 24px;
}
.mar-top-25 {
  margin-top: 25px;
}
.mar-top-26 {
  margin-top: 26px;
}
.mar-top-27 {
  margin-top: 27px;
}
.mar-top-28 {
  margin-top: 28px;
}
.mar-top-29 {
  margin-top: 29px;
}
.mar-top-30 {
  margin-top: 30px;
}
.mar-top-31 {
  margin-top: 31px;
}
.mar-top-32 {
  margin-top: 32px;
}
.mar-top-33 {
  margin-top: 33px;
}
.mar-top-34 {
  margin-top: 34px;
}
.mar-top-35 {
  margin-top: 35px;
}
.mar-top-36 {
  margin-top: 36px;
}
.mar-top-37 {
  margin-top: 37px;
}
.mar-top-38 {
  margin-top: 38px;
}
.mar-top-39 {
  margin-top: 39px;
}
.mar-top-40 {
  margin-top: 40px;
}
.mar-top-41 {
  margin-top: 41px;
}
.mar-top-42 {
  margin-top: 42px;
}
.mar-top-43 {
  margin-top: 43px;
}
.mar-top-44 {
  margin-top: 44px;
}
.mar-top-45 {
  margin-top: 45px;
}
.mar-top-46 {
  margin-top: 46px;
}
.mar-top-47 {
  margin-top: 47px;
}
.mar-top-48 {
  margin-top: 48px;
}
.mar-top-49 {
  margin-top: 49px;
}
.mar-top-45-imp {
  margin-top: 45px !important;
}
.mar-top-50 {
  margin-top: 50px;
}
.mar-top-80 {
  margin-top: 80px;
}
.mar-bot-0 {
  margin-bottom: 0px;
}
.mar-bot-1 {
  margin-bottom: 1px;
}
.mar-bot-2 {
  margin-bottom: 2px;
}
.mar-bot-3 {
  margin-bottom: 3px;
}
.mar-bot-4 {
  margin-bottom: 4px;
}
.mar-bot-5 {
  margin-bottom: 5px;
}
.mar-bot-6 {
  margin-bottom: 6px;
}
.mar-bot-7 {
  margin-bottom: 7px;
}
.mar-bot-8 {
  margin-bottom: 8px;
}
.mar-bot-9 {
  margin-bottom: 9px;
}
.mar-bot-10 {
  margin-bottom: 10px;
}
.mar-bot-11 {
  margin-bottom: 11px;
}
.mar-bot-12 {
  margin-bottom: 12px;
}
.mar-bot-13 {
  margin-bottom: 13px;
}
.mar-bot-14 {
  margin-bottom: 14px;
}
.mar-bot-15 {
  margin-bottom: 15px;
}
.mar-bot-16 {
  margin-bottom: 16px;
}
.mar-bot-17 {
  margin-bottom: 17px;
}
.mar-bot-18 {
  margin-bottom: 18px;
}
.mar-bot-19 {
  margin-bottom: 19px;
}
.mar-bot-20 {
  margin-bottom: 20px;
}
.mar-bot-21 {
  margin-bottom: 21px;
}
.mar-bot-22 {
  margin-bottom: 22px;
}
.mar-bot-23 {
  margin-bottom: 23px;
}
.mar-bot-24 {
  margin-bottom: 24px;
}
.mar-bot-25 {
  margin-bottom: 25px;
}
.mar-bot-26 {
  margin-bottom: 26px;
}
.mar-bot-27 {
  margin-bottom: 27px;
}
.mar-bot-28 {
  margin-bottom: 28px;
}
.mar-bot-29 {
  margin-bottom: 29px;
}
.mar-bot-30 {
  margin-bottom: 30px;
}
.mar-bot-31 {
  margin-bottom: 31px;
}
.mar-bot-32 {
  margin-bottom: 32px;
}
.mar-bot-33 {
  margin-bottom: 33px;
}
.mar-bot-34 {
  margin-bottom: 34px;
}
.mar-bot-35 {
  margin-bottom: 35px;
}
.mar-bot-36 {
  margin-bottom: 36px;
}
.mar-bot-37 {
  margin-bottom: 37px;
}
.mar-bot-38 {
  margin-bottom: 38px;
}
.mar-bot-39 {
  margin-bottom: 39px;
}
.mar-bot-40 {
  margin-bottom: 40px;
}
.mar-bot-41 {
  margin-bottom: 41px;
}
.mar-bot-42 {
  margin-bottom: 42px;
}
.mar-bot-43 {
  margin-bottom: 43px;
}
.mar-bot-44 {
  margin-bottom: 44px;
}
.mar-bot-45 {
  margin-bottom: 45px;
}
.mar-bot-46 {
  margin-bottom: 46px;
}
.mar-bot-47 {
  margin-bottom: 47px;
}
.mar-bot-48 {
  margin-bottom: 48px;
}
.mar-bot-49 {
  margin-bottom: 49px;
}
.mar-bot-50 {
  margin-bottom: 50px;
}
.mar-bot-51 {
  margin-bottom: 51px;
}
.mar-bot-52 {
  margin-bottom: 52px;
}
.mar-bot-53 {
  margin-bottom: 53px;
}
.mar-bot-54 {
  margin-bottom: 54px;
}
.mar-bot-55 {
  margin-bottom: 55px;
}
.mar-bot-56 {
  margin-bottom: 56px;
}
.mar-bot-60 {
  margin-bottom: 60px;
}
.mar-left-0 {
  margin-left: 0px;
}
.mar-left-1 {
  margin-left: 1px;
}
.mar-left-2 {
  margin-left: 2px;
}
.mar-left-3 {
  margin-left: 3px;
}
.mar-left-4 {
  margin-left: 4px;
}
.mar-left-5 {
  margin-left: 5px;
}
.mar-left-6 {
  margin-left: 6px;
}
.mar-left-7 {
  margin-left: 7px;
}
.mar-left-8 {
  margin-left: 8px;
}
.mar-left-9 {
  margin-left: 9px;
}
.mar-left-10 {
  margin-left: 10px;
}
.mar-left-11 {
  margin-left: 11px;
}
.mar-left-12 {
  margin-left: 12px;
}
.mar-left-13 {
  margin-left: 13px;
}
.mar-left-14 {
  margin-left: 14px;
}
.mar-left-15 {
  margin-left: 15px;
}
.mar-left-16 {
  margin-left: 16px;
}
.mar-left-17 {
  margin-left: 17px;
}
.mar-left-18 {
  margin-left: 18px;
}
.mar-left-19 {
  margin-left: 19px;
}
.mar-left-20 {
  margin-left: 20px;
}
.mar-left-21 {
  margin-left: 21px;
}
.mar-left-22 {
  margin-left: 22px;
}
.mar-left-23 {
  margin-left: 23px;
}
.mar-left-24 {
  margin-left: 24px;
}
.mar-left-25 {
  margin-left: 25px;
}
.mar-left-26 {
  margin-left: 26px;
}
.mar-left-27 {
  margin-left: 27px;
}
.mar-left-28 {
  margin-left: 28px;
}
.mar-left-29 {
  margin-left: 29px;
}
.mar-left-30 {
  margin-left: 30px;
}
.mar-left-31 {
  margin-left: 31px;
}
.mar-left-32 {
  margin-left: 32px;
}
.mar-left-33 {
  margin-left: 33px;
}
.mar-left-34 {
  margin-left: 34px;
}
.mar-left-35 {
  margin-left: 35px;
}
.mar-left-36 {
  margin-left: 36px;
}
.mar-left-37 {
  margin-left: 37px;
}
.mar-left-38 {
  margin-left: 38px;
}
.mar-left-39 {
  margin-left: 39px;
}
.mar-left-40 {
  margin-left: 40px;
}
.mar-left-41 {
  margin-left: 41px;
}
.mar-left-42 {
  margin-left: 42px;
}
.mar-left-43 {
  margin-left: 43px;
}
.mar-left-44 {
  margin-left: 44px;
}
.mar-left-45 {
  margin-left: 45px;
}
.mar-left-46 {
  margin-left: 46px;
}
.mar-left-47 {
  margin-left: 47px;
}
.mar-left-48 {
  margin-left: 48px;
}
.mar-left-49 {
  margin-left: 49px;
}
.mar-left-45-imp {
  margin-left: 45px !important;
}
.mar-right-0 {
  margin-right: 0px;
}
.mar-left-50 {
  margin-left: 50px;
}
.mar-right-1 {
  margin-right: 1px;
}
.mar-right-2 {
  margin-right: 2px;
}
.mar-right-3 {
  margin-right: 3px;
}
.mar-right-4 {
  margin-right: 4px;
}
.mar-right-5 {
  margin-right: 5px;
}
.mar-right-6 {
  margin-right: 6px;
}
.mar-right-7 {
  margin-right: 7px;
}
.mar-right-8 {
  margin-right: 8px;
}
.mar-right-9 {
  margin-right: 9px;
}
.mar-right-10 {
  margin-right: 10px;
}
.mar-right-11 {
  margin-right: 11px;
}
.mar-right-12 {
  margin-right: 12px;
}
.mar-right-13 {
  margin-right: 13px;
}
.mar-right-14 {
  margin-right: 14px;
}
.mar-right-15 {
  margin-right: 15px;
}
.mar-right-16 {
  margin-right: 16px;
}
.mar-right-17 {
  margin-right: 17px;
}
.mar-right-18 {
  margin-right: 18px;
}
.mar-right-19 {
  margin-right: 19px;
}
.mar-right-20 {
  margin-right: 20px;
}
.mar-right-21 {
  margin-right: 21px;
}
.mar-right-22 {
  margin-right: 22px;
}
.mar-right-23 {
  margin-right: 23px;
}
.mar-right-24 {
  margin-right: 24px;
}
.mar-right-25 {
  margin-right: 25px;
}
.mar-right-26 {
  margin-right: 26px;
}
.mar-right-27 {
  margin-right: 27px;
}
.mar-right-28 {
  margin-right: 28px;
}
.mar-right-29 {
  margin-right: 29px;
}
.mar-right-30 {
  margin-right: 30px;
}
.mar-right-31 {
  margin-right: 31px;
}
.mar-right-32 {
  margin-right: 32px;
}
.mar-right-33 {
  margin-right: 33px;
}
.mar-right-34 {
  margin-right: 34px;
}
.mar-right-35 {
  margin-right: 35px;
}
.mar-right-36 {
  margin-right: 36px;
}
.mar-right-37 {
  margin-right: 37px;
}
.mar-right-38 {
  margin-right: 38px;
}
.mar-right-39 {
  margin-right: 39px;
}
.mar-right-40 {
  margin-right: 40px;
}
.mar-right-41 {
  margin-right: 41px;
}
.mar-right-42 {
  margin-right: 42px;
}
.mar-right-43 {
  margin-right: 43px;
}
.mar-right-44 {
  margin-right: 44px;
}
.mar-right-45 {
  margin-right: 45px;
}
.mar-right-46 {
  margin-right: 46px;
}
.mar-right-47 {
  margin-right: 47px;
}
.mar-right-48 {
  margin-right: 48px;
}
.mar-right-49 {
  margin-right: 49px;
}
.mar-right-45-imp {
  margin-right: 45px !important;
}
.mar-right-50 {
  margin-left: 50px;
}
.mar-bot-25-imp {
  margin-bottom: 25px !important;
}
.margin-tb-16 {
  margin: 16px 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-rht {
  margin-right: 0;
}
.no-margin-lft {
  margin-left: 0;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-lr {
  margin-left: 0;
  margin-right: 0;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-bot {
  padding-top: 0;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-right {
  padding-right: 0;
}
.pad-5 {
  padding: 5px;
}
.pad-30 {
  padding: 30px;
}
.pad-lft-10 {
  padding-left: 10px;
}
.pad-rht-10 {
  padding-right: 10px;
}
.no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}
.no-padding {
  padding: 0;
}
.no-padding-imp {
  padding: 0 !important;
}
.pad-10 {
  padding: 10px;
}
.pad-15 {
  padding: 15px;
}
.pad-20 {
  padding: 20px;
}

.pad-top-1 {
  padding-top: 1px;
}
.pad-top-2 {
  padding-top: 2px;
}
.pad-top-3 {
  padding-top: 3px;
}
.pad-top-4 {
  padding-top: 4px;
}
.pad-top-5 {
  padding-top: 5px;
}
.pad-top-6 {
  padding-top: 6px;
}
.pad-top-7 {
  padding-top: 7px;
}
.pad-top-8 {
  padding-top: 8px;
}
.pad-top-9 {
  padding-top: 9px;
}
.pad-top-10 {
  padding-top: 10px;
}
.pad-top-11 {
  padding-top: 11px;
}
.pad-top-12 {
  padding-top: 12px;
}
.pad-top-13 {
  padding-top: 13px;
}
.pad-top-14 {
  padding-top: 14px;
}
.pad-top-15 {
  padding-top: 15px;
}
.pad-top-16 {
  padding-top: 16px;
}
.pad-top-17 {
  padding-top: 17px;
}
.pad-top-18 {
  padding-top: 18px;
}
.pad-top-19 {
  padding-top: 19px;
}
.pad-top-20 {
  padding-top: 20px;
}
.pad-top-21 {
  padding-top: 21px;
}
.pad-top-22 {
  padding-top: 22px;
}
.pad-top-23 {
  padding-top: 23px;
}
.pad-top-24 {
  padding-top: 24px;
}
.pad-top-25 {
  padding-top: 25px;
}
.pad-top-26 {
  padding-top: 26px;
}
.pad-top-27 {
  padding-top: 27px;
}
.pad-top-28 {
  padding-top: 28px;
}
.pad-top-29 {
  padding-top: 29px;
}
.pad-top-30 {
  padding-top: 30px;
}
.pad-top-31 {
  padding-top: 31px;
}
.pad-top-32 {
  padding-top: 32px;
}
.pad-top-33 {
  padding-top: 33px;
}
.pad-top-34 {
  padding-top: 34px;
}
.pad-top-35 {
  padding-top: 35px;
}
.pad-top-36 {
  padding-top: 36px;
}
.pad-top-37 {
  padding-top: 37px;
}
.pad-top-38 {
  padding-top: 38px;
}
.pad-top-39 {
  padding-top: 39px;
}
.pad-top-40 {
  padding-top: 40px;
}
.pad-top-41 {
  padding-top: 41px;
}
.pad-top-42 {
  padding-top: 42px;
}
.pad-top-43 {
  padding-top: 43px;
}
.pad-top-44 {
  padding-top: 44px;
}
.pad-top-45 {
  padding-top: 45px;
}
.pad-top-46 {
  padding-top: 46px;
}
.pad-top-47 {
  padding-top: 47px;
}
.pad-top-48 {
  padding-top: 48px;
}
.pad-top-49 {
  padding-top: 49px;
}
.pad-top-50 {
  padding-top: 50px;
}
.pad-top-60 {
  padding-top: 50px;
}
.pad-bot-0 {
  padding-bottom: 0px;
}
.pad-bot-1 {
  padding-bottom: 1px;
}
.pad-bot-2 {
  padding-bottom: 2px;
}
.pad-bot-3 {
  padding-bottom: 3px;
}
.pad-bot-4 {
  padding-bottom: 4px;
}
.pad-bot-5 {
  padding-bottom: 5px;
}
.pad-bot-6 {
  padding-bottom: 6px;
}
.pad-bot-7 {
  padding-bottom: 7px;
}
.pad-bot-8 {
  padding-bottom: 8px;
}
.pad-bot-9 {
  padding-bottom: 9px;
}
.pad-bot-10 {
  padding-bottom: 10px;
}
.pad-bot-11 {
  padding-bottom: 11px;
}
.pad-bot-12 {
  padding-bottom: 12px;
}
.pad-bot-13 {
  padding-bottom: 13px;
}
.pad-bot-14 {
  padding-bottom: 14px;
}
.pad-bot-15 {
  padding-bottom: 15px;
}
.pad-bot-16 {
  padding-bottom: 16px;
}
.pad-bot-17 {
  padding-bottom: 17px;
}
.pad-bot-18 {
  padding-bottom: 18px;
}
.pad-bot-19 {
  padding-bottom: 19px;
}
.pad-bot-20 {
  padding-bottom: 20px;
}
.pad-bot-21 {
  padding-bottom: 21px;
}
.pad-bot-22 {
  padding-bottom: 22px;
}
.pad-bot-23 {
  padding-bottom: 23px;
}
.pad-bot-24 {
  padding-bottom: 24px;
}
.pad-bot-25 {
  padding-bottom: 25px;
}
.pad-bot-26 {
  padding-bottom: 26px;
}
.pad-bot-27 {
  padding-bottom: 27px;
}
.pad-bot-28 {
  padding-bottom: 28px;
}
.pad-bot-29 {
  padding-bottom: 29px;
}
.pad-bot-30 {
  padding-bottom: 30px;
}
.pad-bot-31 {
  padding-bottom: 31px;
}
.pad-bot-32 {
  padding-bottom: 32px;
}
.pad-bot-33 {
  padding-bottom: 33px;
}
.pad-bot-34 {
  padding-bottom: 34px;
}
.pad-bot-35 {
  padding-bottom: 35px;
}
.pad-bot-36 {
  padding-bottom: 36px;
}
.pad-bot-37 {
  padding-bottom: 37px;
}
.pad-bot-38 {
  padding-bottom: 38px;
}
.pad-bot-39 {
  padding-bottom: 39px;
}
.pad-bot-40 {
  padding-bottom: 40px;
}
.pad-bot-41 {
  padding-bottom: 41px;
}
.pad-bot-42 {
  padding-bottom: 42px;
}
.pad-bot-43 {
  padding-bottom: 43px;
}
.pad-bot-44 {
  padding-bottom: 44px;
}
.pad-bot-45 {
  padding-bottom: 45px;
}
.pad-bot-46 {
  padding-bottom: 46px;
}
.pad-bot-47 {
  padding-bottom: 47px;
}
.pad-bot-48 {
  padding-bottom: 48px;
}
.pad-bot-49 {
  padding-bottom: 49px;
}
.pad-bot-50 {
  padding-bottom: 50px;
}
.pad-bot-71 {
  padding-bottom: 71px;
}
.pad-left-0 {
  padding-left: 0px;
}
.pad-left-1 {
  padding-left: 1px;
}
.pad-left-2 {
  padding-left: 2px;
}
.pad-left-3 {
  padding-left: 3px;
}
.pad-left-4 {
  padding-left: 4px;
}
.pad-left-5 {
  padding-left: 5px;
}
.pad-left-6 {
  padding-left: 6px;
}
.pad-left-7 {
  padding-left: 7px;
}
.pad-left-8 {
  padding-left: 8px;
}
.pad-left-9 {
  padding-left: 9px;
}
.pad-left-10 {
  padding-left: 10px;
}
.pad-left-11 {
  padding-left: 11px;
}
.pad-left-12 {
  padding-left: 12px;
}
.pad-left-13 {
  padding-left: 13px;
}
.pad-left-14 {
  padding-left: 14px;
}
.pad-left-15 {
  padding-left: 15px;
}
.pad-left-16 {
  padding-left: 16px;
}
.pad-left-17 {
  padding-left: 17px;
}
.pad-left-18 {
  padding-left: 18px;
}
.pad-left-19 {
  padding-left: 19px;
}
.pad-left-20 {
  padding-left: 20px;
}
.pad-left-21 {
  padding-left: 21px;
}
.pad-left-22 {
  padding-left: 22px;
}
.pad-left-23 {
  padding-left: 23px;
}
.pad-left-24 {
  padding-left: 24px;
}
.pad-left-25 {
  padding-left: 25px;
}
.pad-left-26 {
  padding-left: 26px;
}
.pad-left-27 {
  padding-left: 27px;
}
.pad-left-28 {
  padding-left: 28px;
}
.pad-left-29 {
  padding-left: 29px;
}
.pad-left-30 {
  padding-left: 30px;
}
.pad-left-31 {
  padding-left: 31px;
}
.pad-left-32 {
  padding-left: 32px;
}
.pad-left-33 {
  padding-left: 33px;
}
.pad-left-34 {
  padding-left: 34px;
}
.pad-left-35 {
  padding-left: 35px;
}
.pad-left-36 {
  padding-left: 36px;
}
.pad-left-37 {
  padding-left: 37px;
}
.pad-left-38 {
  padding-left: 38px;
}
.pad-left-39 {
  padding-left: 39px;
}
.pad-left-40 {
  padding-left: 40px;
}
.pad-left-41 {
  padding-left: 41px;
}
.pad-left-42 {
  padding-left: 42px;
}
.pad-left-43 {
  padding-left: 43px;
}
.pad-left-44 {
  padding-left: 44px;
}
.pad-left-45 {
  padding-left: 45px;
}
.pad-left-46 {
  padding-left: 46px;
}
.pad-left-47 {
  padding-left: 47px;
}
.pad-left-48 {
  padding-left: 48px;
}
.pad-left-49 {
  padding-left: 49px;
}
.pad-left-50 {
  padding-left: 50px;
}
.pad-left-80 {
  padding-left: 50px;
}
.pad-right-0 {
  padding-right: 1px;
}
.pad-right-1 {
  padding-right: 1px;
}
.pad-right-2 {
  padding-right: 2px;
}
.pad-right-3 {
  padding-right: 3px;
}
.pad-right-4 {
  padding-right: 4px;
}
.pad-right-5 {
  padding-right: 5px;
}
.pad-right-6 {
  padding-right: 6px;
}
.pad-right-7 {
  padding-right: 7px;
}
.pad-right-8 {
  padding-right: 8px;
}
.pad-right-9 {
  padding-right: 9px;
}
.pad-right-10 {
  padding-right: 10px;
}
.pad-right-11 {
  padding-right: 11px;
}
.pad-right-12 {
  padding-right: 12px;
}
.pad-right-13 {
  padding-right: 13px;
}
.pad-right-14 {
  padding-right: 14px;
}
.pad-right-15 {
  padding-right: 15px;
}
.pad-right-16 {
  padding-right: 16px;
}
.pad-right-17 {
  padding-right: 17px;
}
.pad-right-18 {
  padding-right: 18px;
}
.pad-right-19 {
  padding-right: 19px;
}
.pad-right-20 {
  padding-right: 20px;
}
.pad-right-21 {
  padding-right: 21px;
}
.pad-right-22 {
  padding-right: 22px;
}
.pad-right-23 {
  padding-right: 23px;
}
.pad-right-24 {
  padding-right: 24px;
}
.pad-right-25 {
  padding-right: 25px;
}
.pad-right-26 {
  padding-right: 26px;
}
.pad-right-27 {
  padding-right: 27px;
}
.pad-right-28 {
  padding-right: 28px;
}
.pad-right-29 {
  padding-right: 29px;
}
.pad-right-30 {
  padding-right: 30px;
}
.pad-right-31 {
  padding-right: 31px;
}
.pad-right-32 {
  padding-right: 32px;
}
.pad-right-33 {
  padding-right: 33px;
}
.pad-right-34 {
  padding-right: 34px;
}
.pad-right-35 {
  padding-right: 35px;
}
.pad-right-36 {
  padding-right: 36px;
}
.pad-right-37 {
  padding-right: 37px;
}
.pad-right-38 {
  padding-right: 38px;
}
.pad-right-39 {
  padding-right: 39px;
}
.pad-right-40 {
  padding-right: 40px;
}
.pad-right-41 {
  padding-right: 41px;
}
.pad-right-42 {
  padding-right: 42px;
}
.pad-right-43 {
  padding-right: 43px;
}
.pad-right-44 {
  padding-right: 44px;
}
.pad-right-45 {
  padding-right: 45px;
}
.pad-right-46 {
  padding-right: 46px;
}
.pad-right-47 {
  padding-right: 47px;
}
.pad-right-48 {
  padding-right: 48px;
}
.pad-right-49 {
  padding-right: 49px;
}
.pad-right-50 {
  padding-right: 50px;
}
.pad-right-80 {
  padding-right: 50px;
}
.pad-0-15 {
  padding: 0 15px;
}

.primary {
  color: var(--primary);
}
.accent {
  color: var(--accent);
}
.accenttwo {
  color: var(--accenttwo);
}

.bs-popover-top,
.bs-popover-bottom,
.bs-popover-right,
.bs-popover-left {
  border-radius: 4px;
  border: 1px solid var(--primary);
  text-align: center;
  min-width: 200px;
}
.loader {
  border: 1px solid var(--accent);
  border-radius: 50%;
  border-top: 1px solid var(--primary);
  width: 17px;
  height: 17px;
  -webkit-animation: spin 2s linear infinite;
  // opacity: 0.85;
  animation: spin 2s linear infinite;
}

.loader-flight {
  // animation: spin 2s linear infinite;

  position: fixed;
  display: flex;
  justify-content: center;
  top: 47vh;
  width: 100%;
  height: 93px;
  z-index: 9999;
  overflow: hidden;
  @media (max-width: 576px) {
    height: 48px;
    width: 100%;
    top: 42vh;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-loader-default {
  position: absolute;
  top: 10px;
  right: 6px;
}
@media (max-width: 992px) {
  .d-none-992 {
    display: none;
  }
  .d-block-992 {
    display: block;
  }
  .d-flex-992 {
    display: flex;
  }
  .d-inline-block-992 {
    display: inline-block;
  }
  .w-auto-992 {
    width: auto;
  }
}
@media (max-width: 768px) {
  .d-none-768 {
    display: none;
  }
}
@media (max-width: 576px) {
  .d-none-576 {
    display: none;
  }
}
@media (min-width: 768px) {
  .d-none-sm {
    display: none;
  }
  .d-block-sm {
    display: block;
  }
}
@media (min-width: 992px) {
  .d-none-md {
    display: none;
  }
  .d-block-md {
    display: block;
  }
}
//Framework-Amit common Classes ends
// gift search
@media (max-width: 768px) {
  .has-go {
    #search-bar {
      .search-drop {
        width: calc(100% - 140px) !important;
      }
      .go-btn {
        display: flex !important;
      }
    }
  }
}
button:focus {
  outline: none;
}
.cvv-modal {
  margin: 50% 0 0;
  .modal-content {
    border: 0;
    border-radius: 0;
  }
}

.height-45 {
  height: 40px;
}

:focus {
  outline: none;
}

.modal.fade.in {
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 1111111;
}
.modal-backdrop {
  z-index: 0;
}
.modal-backdrop.show {
  opacity: 0;
}

.whitebg {
  background: var(--white) !important;
}
.toast-success {
  // border-left: 4px solid #53CA0D;
  background-color: var(--greenBackground);
  color: #fff !important;
  background-image: url("assets/success.svg");
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: row-reverse;
}

.toast-error {
  // border-left: 4px solid #FFA800;
  background-color: #670000;
  color: #fff !important;
  background-image: url("assets/error.svg");
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: row-reverse;
}
.toast-close-button {
  position: static;
  font-size: 20px !important;
  color: var(--secblackone) !important;
  margin-left: 15px;
}
.toast-container {
  width: 100%;
}
.toast-container .ngx-toastr {
  // width: fit-content !important;
  width: 100%;
  font-size: 14px;
  padding: 5px 9.1%;
  background-position: calc(9.1% - 30px) center;
  background-size: 23px;
  box-shadow: none;
  @media (max-width: 576px) {
    // width: 90vw;
    width: 100%;
  }
  // width: 350px !important;
}

.txt-align-right {
  text-align: right !important;
}

.ngx-pagination .current {
  background: var(--primary) !important;
  color: var(--secblack) !important;
}

.ngx-pagination a {
  color: var(--accenttwo) !important;
  &:hover {
    background: var(--blacktwo) !important;
  }
}

.zoom {
  transition: transform 1s;
  overflow: hidden;
}

.zoom:hover {
  transform: scale(1.1);
  overflow: hidden;
}

.bg-primary {
  border: 1px solid var(--primary) !important;
  border-radius: 25px !important;
  background-color: transparent !important;
  color: var(--black) !important;
}

.btn-light:hover {
  border-radius: 25px !important;
}

.btn-light {
  border-radius: 25px !important;
}

.btn-link {
  color: var(--accent) !important;
}
.ngb-dp-weekday {
  font-style: initial !important;
}
.btn-light:not(:disabled):not(.disabled):active {
  border-radius: 25px !important;
}

.modal-dialog {
  .modal-body {
    padding: 0;
  }
  .map-close-button {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 999;
    padding: 10px 15px;
    background: var(--accent);
    margin-right: 10px;
    margin-top: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05), 0px -2px 10px rgba(0, 0, 0, 0.05);
  }
}
.ngx-pagination {
  padding-left: 0;
}
// ..mat-drawer-content {
//   overflow: hidden;
// }

.popover-header {
  font-size: 14px;
  color: var(--accent);
  background: var(--background);
  text-align: left;
}
.popover-content {
  text-align: left;
}

.swal2-popup {
  background: var(--blacktwo) !important;
}

.swal2-title {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--accent) !important;
}

.swal2-html-container {
  p {
    text-align: left;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: var(--secblack) !important;
    padding: 0 3vw !important;
    margin-bottom: 0;
    color: var(--accent) !important;
  }
}

.swal2-confirm {
  background: var(--primary) !important;
  color: var(--secblack) !important;
  border: none !important;
  border-radius: 25px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 24px !important;
}

.swal2-cancel {
  background: var(--secblack) !important;
  color: var(--primary) !important;
  border: none !important;
  border-radius: 25px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 24px !important;
}

.swal2-validation-message {
  font-size: 14px !important;
  background: none !important;
  color: var(--red) !important;
  margin: 0 !important;
}

.swal2-cancel-input {
  font-size: 14px !important;
}

.swal2-textarea {
  font-size: 14px !important;
  resize: none !important;
  // padding: 0 3vw !important;
}
.swal2-validation-message {
  font-size: 14px !important;
}
.mat-drawer-container {
  background: white !important;
}

.reward-points .mat-radio-label-content {
  width: 100%;
}

@media screen {
  // p.bodyText {font-family:verdana, arial, sans-serif;}
  .to-hide1 {
    /* hide the printing component from @media screen */
    display: none !important;
  }
}

@media print {
  // p.bodyText {font-family:georgia, times, serif;}
  body{
    background: white !important;
  }
  .container {
    background-color: #fff;
  }
  .to-hide1 {
    display: block !important;
    -webkit-print-color-adjust: exact;
  }
  .d-sm-block {
    display: none;
  }
  .to-show1 {
    display: none !important;
  }
  app-header {
    display: none !important;
  }
  app-footer {
    display: none !important;
  }
}

$primary-color: #dadada;
$animation-duration: 0.4s;

@mixin label-inactive() {
  font-size: 1em;
  transform: translateY(0);
}

@mixin label-active() {
  font-size: 0.8em;
  transform: translateY(-20px);
}

.form-field {
  display: block;
  // margin-bottom: 16px;
  color: var(--accenttwo);
}
.form-field__label {
  @include label-active();
  display: block;
  font-weight: 300;
  left: 0;
  margin: 0;
  padding: 20px 0px 0;
  position: absolute;
  top: 0;
  transition: all $animation-duration;
  width: 100%;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-field__control {
  // background: #eee;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  &.datepicker {
    position: static;
  }
}
// .form-field__bar {
//   // border-bottom: 2px solid $primary-color;
//   bottom: 0;
//   content: "";
//   display: block;
//   left: 0;
//   margin: 0 auto;
//   position: absolute;
//   right: 0;
//   transform: scaleX(0);
//   transition: all $animation-duration;
//   width: 1%;
// }
.form-field__input,
.form-field__textarea {
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--secondarygrey);
  color: var(--accent);
  display: block;
  font-size: 1em;
  font-weight: 300;
  margin-top: 20px;
  outline: 0;
  padding: 0 12px 0px 0px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
  &.arrows {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMi4wMTEgNTEyLjAxMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyLjAxMSA1MTIuMDExOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIGNsYXNzPSIiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTUwNS43NTUsMTIzLjU5MmMtOC4zNDEtOC4zNDEtMjEuODI0LTguMzQxLTMwLjE2NSwwTDI1Ni4wMDUsMzQzLjE3NkwzNi40MjEsMTIzLjU5MmMtOC4zNDEtOC4zNDEtMjEuODI0LTguMzQxLTMwLjE2NSwwICAgIHMtOC4zNDEsMjEuODI0LDAsMzAuMTY1bDIzNC42NjcsMjM0LjY2N2M0LjE2LDQuMTYsOS42MjEsNi4yNTEsMTUuMDgzLDYuMjUxYzUuNDYyLDAsMTAuOTIzLTIuMDkxLDE1LjA4My02LjI1MWwyMzQuNjY3LTIzNC42NjcgICAgQzUxNC4wOTYsMTQ1LjQxNiw1MTQuMDk2LDEzMS45MzMsNTA1Ljc1NSwxMjMuNTkyeiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4=");
    background-position: calc(100% - 0.1rem), 100% 10px;
    background-size: 0.8em 0.8em;
    background-repeat: no-repeat;
  }
  &.error {
    border-bottom: 1px solid var(--red);
  }
  // IE 10-11
  &:-ms-input-placeholder {
    ~ .form-field__label {
      @include label-inactive();
    }
  }
  // All other browsers except Edge
  &:placeholder-shown {
    ~ .form-field__label {
      @include label-inactive();
    }
  }
  &:focus {
    ~ .form-field__label {
      color: --accent;
      @include label-active();
    }
    // ~ .form-field__bar {
    //   border-bottom: 2px solid $primary-color;
    //   transform: scaleX(150);
    // }
  }
}
.form-field__textarea {
  height: 150px;
}

.main-button {
  .mat-button {
    background-color: var(--primary);
    color: var(--secblack);
    border-radius: 25px;
  }
}

.mat-button {
  background-color: var(--primary);
  color: var(--secblack);
  border-radius: 25px;
  font-weight: 400 !important;
}

.checkmark {
  position: relative;
  margin-left: auto;
  height: 16px;
  width: 16px;
  // background-color: var(--primary);
  border: 1px solid var(--accent);
  border-radius: 2px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.filter-label {
  color: var(--accenttwo);
  font-weight: 300;
  &.bold-it {
    font-weight: 400;
    color: var(--accent);
  }
}
.filter-label:hover input ~ .checkmark {
  // background-color: var(--primary);
}

/* When the checkbox is checked, add a blue background */

.filter-label input:checked ~ .checkmark {
  border: 1px solid var(--primary);
  background: var(--primary);
}

.filter-label input:checked ~ .filter-label-text {
  color: var(--accent);
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.filter-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.filter-label .checkmark:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 5px;
  top: 1px;
  width: 5px;
  height: 11px;
  border: solid var(--background);
  border-width: 0 2px 2px 0;
}

.filter-label input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

label {
  display: inline-flex;
  // padding-top: 42px;
}

label {
  &.radio__label {
    padding-top: 0;
  }
}

.datepicker-golf-preferences {
  border: none;
  background: var(--blacktwo);
  padding: 10px 20px;
  .ngb-dp-header {
    padding: 0;
  }

  .ngb-dp-week {
    background-color: transparent;
    border-bottom: none;
  }

  .ngb-dp-weekday {
    color: var(--accenttwo);
    font-size: 12px;
    line-height: 2.5rem;
  }

  .ngb-dp-day,
  .ngb-dp-weekday {
    width: 3rem;
    height: 2.5rem;
  }
}

.datepicker {
  .ngb-dp-header {
    background-color: var(--blacktwo);
    .custom-select {
      color: var(--accent);
      background-color: var(--blacktwo);
    }
  }
  .ngb-dp-weekdays {
    background: var(--blacktwo);
    .ngb-dp-weekday {
      color: var(--accent);
    }
  }
  .ngb-dp-week {
    .ngb-dp-day {
      .btn-light {
        color: var(--accent);
      }
      .btn-light:hover {
        color: black;
        // background-color: #e2e6ea;
        // border-color: #dae0e5;
      }
      .btn-light:focus {
        color: black;
      }
      .btn-light:not(:disabled):not(.disabled):active {
        color: black;
      }
      .bg-primary {
        color: var(--accent) !important;
      }
    }
  }
}

hr {
  border-top: 1px solid var(--black);
}
.all-mob-dropdowns {
  position: fixed;
  top: 100%;
  z-index: 100011;
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  .close-bar {
    height: 50px;
  }
  .close-icon-contain {
    margin-left: 15px;
    padding: 5px;
  }

  .txt-box {
    padding-left: 5px;
    color: var(--black);
    line-height: 20px;
  }

  .input-contain {
    padding: 0 10px 15px;
    border-bottom: 1px solid #e3e5e7;
    .form-control {
      background-color: var(--background);
      border-radius: 30px;
      border: 0;
      font-size: 14px;
      color: var(--black);
      padding: 0 20px;
      height: 52px;
    }
    .form-control:focus {
      box-shadow: 0 0 0 1px var(--primary);
    }
  }
  .option-contain {
    overflow: auto;
    height: 71vh;
    padding-bottom: 40px !important;
    .country-img {
      width: 20px;
    }
    .country-code,
    .airport-name {
      color: var(--secblackone);
    }
    .option {
      padding: 10px 15px 10px;
    }
    .country-contain {
      height: 20px;
      width: 30%;
      justify-content: flex-end;
    }
    .country-name {
      padding-right: 10px;
      white-space: nowrap;
    }
    .airport-contain {
      width: 70%;
    }
  }
  &.active {
    top: 0;
  }

  .mob-drop-heading {
    padding: 8px 17px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
  }

  .map-done {
    position: fixed;
    bottom: 0;
  }

  .icon-search {
    padding: 0.35rem;
  }
}

.ngx-gallery-thumbnail {
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.ngx-gallery-icon {
  color: var(--secondarygrey) !important;
}

p {
  a:hover {
    // color: var(--primary);
    font-size: inherit;
    text-decoration: underline;
  }
}

.btn-outline-light:hover {
  color: var(--secblack);
  background-color: var(--primary);
  border-color: var(--primary);
}

.invalid-feedback {
  color: var(--red) !important;
}

.error-msg{
  color: var(--red);
}

.mob-no-padding {
  @media (max-width: 576px) {
    padding: 0;
  }
}

// style for scrollbar

::-webkit-scrollbar,
.scroller {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1f1f1f;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #101010;
}

:root {
  scrollbar-color: #1f1f1f #101010;
  scrollbar-width: 10px;
}
.mob-no-margin {
  @media (max-width: 576px) {
    padding: 0;
  }
}

.no-margin-md{
  @media (max-width: 576px) {
    margin: 0!important;
  }
}
.no-padding-md{
  @media (max-width: 576px) {
    padding: 0!important;
  }
}

.modal-dialog {
  border-radius: 8px;
  border: 1px solid var(--primary);

  @media (max-width: 576px) {
    margin: 4.5rem 0.5rem;
  }
  .modal-content {
    border: none;
  }
}

.pad-20-y{
  padding-top:1.25rem;
  padding-bottom:1.25rem;
}

.pad-20-x{
  padding-right:1.25rem;
  padding-left:1.25rem;
}

.pad-md-20-x{
  @media (min-width:768px) {
    padding-right:1.25rem!important;
    padding-left:1.25rem!important;
  }
}

.pad-md-20-y{
  @media (min-width:768px) {
    padding-top:1.25rem!important;
    padding-top:1.25rem!important;
  }
}

.pad-md-20px{
  @media (min-width:768px) {
    padding:1.25rem!important;
  }
}

.pad-md-24-x{
  @media (min-width:768px) {
    padding-right:1.5rem!important;
    padding-left:1.5rem!important;
  }
}

.pad-md-0-x{
  @media (min-width:768px) {
    padding-right:0rem!important;
    padding-left:0rem!important;
  }
}

.pad-md-24-y{
  @media (min-width:768px) {
    padding-top:1.5rem!important;
    padding-bottom:1.5rem!important;
  }
}

.pad-md-28-y{
  @media (min-width:768px) {
    padding-top:1.75rem!important;
    padding-bottom:1.75rem!important;
  }
}

.pad-md-40-y{
  @media (min-width:768px) {
    padding-top:2.5rem;
    padding-bottom:2.5rem;
  }
}

.pad-16-x {
  padding-right:1rem;
  padding-left:1rem;
}

.pad-16-y {
  padding-top:1rem;
  padding-bottom:1rem;
}

.mar-16-b {
  margin-bottom: 1rem;
}

.mar-md-20-b{
  @media (min-width:768px) {
    margin-bottom: 1.25rem!important;
  }
}

.mar-md-40-b {
  @media (min-width:768px) {
    margin-bottom: 2.5rem!important;
  }
}

.font-10px {
  font-size: 0.625rem;
}

.font-13px {
  font-size: 0.83rem;
}

.font-11px {
  font-size: 0.7rem;
}

.font-14px {
  font-size: 0.875rem;
}

.font-16px {
  font-size: 1rem;
}

.font-12px {
  font-size: 0.75rem;
}

.font-md-11px {
  @media (min-width:768px) {
    font-size: 0.6875rem!important;
  }
}
.font-md-12px {
  @media (min-width:768px) {
    font-size: 0.75rem!important;
  }
}

.font-md-14px {
  @media (min-width:768px) {
    font-size: 0.875rem;
  }
}

.font-md-32px{
  @media (min-width:768px) {
    font-size: 2rem!important;
  }
}

.font-md-16px{
  @media (min-width:768px) {
    font-size: 1rem!important;
  }
}

.font-md-18px{
  @media (min-width:768px) {
    font-size: 1.125rem!important;
  }
}

.font-md-20px{
  @media (min-width:768px) {
    font-size: 1.25rem!important;
  }
}

.border-md-1px{
  @media (min-width:768px) {
    border: 1px solid #717171;
  }
}
.border-radius-md{
  @media (min-width:768px) {
    border-radius: 8px;
  }
}

.text-dark-grey {
  color: #676767;
}

.text-grey {
  color: #A8A8A8;
}

.text-grey-light {
  color: #DADADA;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-brand {
  color: #C2A63F;
}

.text-md-brand{
  @media (min-width:768px) {
    color: #C2A63F!important;
  }
}

.border-brand-md-1px{
  @media (min-width:768px) {
    border: 1px solid #C2A63F;
  }
}

.account-modal {

  .modal-content {
    background: #101010;
    border: 1px solid var(--primary);
  }
  .modal-dialog {
    border: none !important;
    // min-height: 65vh;

  }
  @media (max-width: 761px) {
    .modal-dialog {
      display: flex;
      min-height: 51vh;
      position: fixed;
      margin: 0;
      bottom: 0;
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      border: none !important;
      border-top: 1px solid #918141 !important;
      @media (min-width: 768px) {
        left: calc(50vw - 208px);
      }
      .modal-content {
        background: #101010;
        border-radius: 12px 12px 0px 0px;
        border: none;
        // border-radius: 16px 16px 0 0;

      }
    }
    &.fade {
      .modal-dialog {
        transform: none;
      }
    }
  }
}


.navmodal {
    @media (max-width: 761px) {
      .modal-dialog {
        background: #101010;
        min-height: auto;
      }
      .modal-content{
        margin-top: auto;
      }
    }
}

.account-modal-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
  opacity: 1 !important;
}

app-read-more{
  ul{
    list-style-position: inside;
    padding-left: 0;
  }
}

.cdk-overlay-container{
  z-index: 1200;
}

.line-clamp{
  p{
    display: inline;
  }
  li{
    overflow: visible;
    -webkit-box-orient: horizontal;
  }
}
.app-read-more{
  li{
    overflow: visible;
    -webkit-box-orient: horizontal;
  }
}
